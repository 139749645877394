import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Typography, Container, MenuItem, CircularProgress, Backdrop,
  FormControl, InputLabel, Select, FormControlLabel, RadioGroup, Radio,
  Accordion, AccordionSummary, AccordionDetails, Box, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText,
  Chip
} from '@mui/material';
import { db, auth, storage } from '../api/firebase';
import { doc, getDoc, addDoc, collection, serverTimestamp, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { v4 as uuidv4 } from 'uuid';
import PostContent from './PostContent';
import ImageRenderer from './ImageRenderer';

const categories = [
  "サイトについて",
  "本人確認について",
  "音源について",
  "マスターマインドメンバーズについて",
  "グループ活動について",
  "その他"
];

function SupportPage() {
  const [category, setCategory] = useState('');
  const [message, setMessage] = useState('');
  const [useDefaultEmail, setUseDefaultEmail] = useState(true);
  const [replyEmail, setReplyEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({ name: '', email: '', isAdmin: false });
  const [faqCategory, setFaqCategory] = useState('');
  const [faqQuestion, setFaqQuestion] = useState('');
  const [faqAnswer, setFaqAnswer] = useState('');
  const [faqImages, setFaqImages] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [faqDialogOpen, setFaqDialogOpen] = useState(false);
  const [editingFaq, setEditingFaq] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserData(userSnap.data());
          setReplyEmail(userSnap.data().email);
        }
      }
    };

    fetchUserData();
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    const faqCollection = collection(db, "faqs");
    const faqSnapshot = await getDocs(faqCollection);
    const faqData = faqSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setFaqs(faqData);
  };

  const groupFaqsByCategory = (faqs) => {
    return faqs.reduce((groups, faq) => {
      const category = faq.category || "その他";
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(faq);
      return groups;
    }, {});
  };

  const groupedFaqs = groupFaqsByCategory(faqs);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const supportRef = collection(db, "support");
    try {
      await addDoc(supportRef, {
        name: userData.name,
        email: userData.email,
        replyEmail: useDefaultEmail ? userData.email : replyEmail,
        category: category,
        message: message,
        groupId: userData.participatingGroup ? userData.participatingGroup : "",
        createdAt: serverTimestamp(),
        isResolved: false,
        userId: auth.currentUser.uid,
        avatar: userData.avatar,
        loginEmail: userData.email,
      });
      setTimeout(() => {
        setLoading(false);
        navigate('/contact-confirmation');
      }, 2000);
    } catch (error) {
      console.error("Error adding document: ", error);
      setLoading(false);
    }
  };

  const handleFaqSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const faqRef = collection(db, "faqs");
    let imageUrls = [];

    for (const image of faqImages) {
      if (image.file) {
        const uniqueFileName = `${uuidv4()}-${image.file.name}`;
        const storageRef = ref(storage, `faqs/${uniqueFileName}`);
        const uploadTask = uploadBytesResumable(storageRef, image.file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            snapshot => {},
            error => {
              console.error("Error uploading image: ", error);
              setLoading(false);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              imageUrls.push(downloadURL);
              resolve();
            }
          );
        });
      } else {
        imageUrls.push(image.url);
      }
    }

    try {
      await addDoc(faqRef, {
        category: faqCategory,
        question: faqQuestion,
        answer: faqAnswer,
        images: imageUrls.map((url, index) => ({ url, order: index })),
        createdAt: serverTimestamp(),
      });
      setFaqCategory('');
      setFaqQuestion('');
      setFaqAnswer('');
      setFaqImages([]);
      setFaqDialogOpen(false);
      fetchFaqs();
      setLoading(false);
    } catch (error) {
      console.error("Error adding FAQ: ", error);
      setLoading(false);
    }
  };

  const handleFaqEdit = async () => {
    if (!editingFaq) return;
    setLoading(true);
    const faqDocRef = doc(db, "faqs", editingFaq.id);
    let imageUrls = [];

    for (const image of faqImages) {
      if (image.file) {
        const uniqueFileName = `${uuidv4()}-${image.file.name}`;
        const storageRef = ref(storage, `faqs/${uniqueFileName}`);
        const uploadTask = uploadBytesResumable(storageRef, image.file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            snapshot => {},
            error => {
              console.error("Error uploading image: ", error);
              setLoading(false);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              imageUrls.push(downloadURL);
              resolve();
            }
          );
        });
      } else {
        imageUrls.push(image.url);
      }
    }

    try {
      await updateDoc(faqDocRef, {
        category: faqCategory,
        question: faqQuestion,
        answer: faqAnswer,
        images: imageUrls.map((url, index) => ({ url, order: index })),
        updatedAt: serverTimestamp(),
      });
      setFaqCategory('');
      setFaqQuestion('');
      setFaqAnswer('');
      setFaqImages([]);
      setEditingFaq(null);
      setFaqDialogOpen(false);
      fetchFaqs();
      setLoading(false);
    } catch (error) {
      console.error("Error updating FAQ: ", error);
      setLoading(false);
    }
  };

  const handleFaqDelete = async (faqId) => {
    setLoading(true);
    const faqDocRef = doc(db, "faqs", faqId);
    try {
      const faqSnapshot = await getDoc(faqDocRef);
      const faqData = faqSnapshot.data();
      if (faqData.images) {
        for (const image of faqData.images) {
          const storageRef = ref(storage, image.url);
          try {
            await deleteObject(storageRef);
          } catch (error) {
            console.error("Error deleting image: ", error);
          }
        }
      }
      await deleteDoc(faqDocRef);
      fetchFaqs();
      setLoading(false);
    } catch (error) {
      console.error("Error deleting FAQ: ", error);
      setLoading(false);
    }
  };

  const handleFaqDialogOpen = (faq = null) => {
    if (faq) {
      setFaqCategory(faq.category);
      setFaqQuestion(faq.question);
      setFaqAnswer(faq.answer);
      setFaqImages(faq.images ? faq.images.map((image) => ({ url: image.url })) : []);
      setEditingFaq(faq);
    } else {
      setFaqCategory('');
      setFaqQuestion('');
      setFaqAnswer('');
      setFaqImages([]);
      setEditingFaq(null);
    }
    setFaqDialogOpen(true);
  };

  const handleFaqDialogClose = () => {
    setFaqDialogOpen(false);
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setFaqImages((prevImages) => prevImages.concat(filesArray));
    }
  };

  const handleImageDelete = async (index) => {
    const imageToDelete = faqImages[index];
    if (imageToDelete.url) {
      const storageRef = ref(storage, `faqs/${imageToDelete.url.split('/').pop().split('?')[0]}`);
      try {
        await deleteObject(storageRef);
      } catch (error) {
        console.error("Error deleting image: ", error);
      }
    }
    setFaqImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleMoveImage = (index, direction) => {
    setFaqImages((prevImages) => {
      const newImages = [...prevImages];
      const targetIndex = index + direction;
      if (targetIndex < 0 || targetIndex >= newImages.length) return newImages;
      [newImages[index], newImages[targetIndex]] = [newImages[targetIndex], newImages[index]];
      return newImages;
    });
  };

  

  return (
    <Container component="main" maxWidth="sm">
      {userData.isAdmin && (
        <>
          <Button variant="outlined" onClick={() => handleFaqDialogOpen()} sx={{ mb: 3 }}>
            よくある質問を投稿
          </Button>
          <Dialog open={faqDialogOpen} onClose={handleFaqDialogClose}>
            <DialogTitle>{editingFaq ? "よくある質問を編集" : "よくある質問を投稿"}</DialogTitle>
            <DialogContent>
              <TextField
                required
                fullWidth
                label="カテゴリ"
                value={faqCategory}
                onChange={e => setFaqCategory(e.target.value)}
                margin="normal"
                select
              >
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                fullWidth
                label="質問"
                value={faqQuestion}
                onChange={e => setFaqQuestion(e.target.value)}
                margin="normal"
              />
              <TextField
                required
                fullWidth
                label="回答"
                value={faqAnswer}
                onChange={e => setFaqAnswer(e.target.value)}
                margin="normal"
              />
              <Button
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
                sx={{ mt: 2 }}
              >
                画像を追加
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple
                />
              </Button>
              <List>
                {faqImages.map((image, index) => (
                  <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <img
                        src={image.preview || image.url}
                        alt={`FAQ ${index + 1}`}
                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                      />
                    </ListItemIcon>
                    <IconButton
                      onClick={() => handleMoveImage(index, -1)}
                      disabled={index === 0}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleMoveImage(index, 1)}
                      disabled={index === faqImages.length - 1}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <ListItemText primary={`画像 ${index + 1}`} />
                    <IconButton onClick={() => handleImageDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFaqDialogClose} color="primary">
                キャンセル
              </Button>
              <Button onClick={editingFaq ? handleFaqEdit : handleFaqSubmit} color="primary" disabled={loading}>
                {editingFaq ? "更新" : "投稿"}
              </Button>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
                <Typography sx={{ mt: 2 }}>送信中...</Typography>
              </Backdrop>
            </DialogActions>
          </Dialog>
        </>
      )}

      <Typography variant="h6" gutterBottom>
        よくある質問
      </Typography>
      {Object.keys(groupedFaqs).map((category) => (
        <Box key={category} sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {category}
          </Typography>
          {groupedFaqs[category].map(faq => (
            <Accordion key={faq.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Chip
                    label={faq.category}
                    sx={{
                      marginRight: 2,
                      backgroundColor: 'lightblue', // カテゴリに応じた色
                      color: 'black', // テキストカラー
                      borderRadius: '4px', // 角丸
                      fontWeight: 'bold',
                    }}
                  />
                  <Typography variant="body1" color="primary">Q. {faq.question} </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" color="secondary">A. <PostContent content={faq.answer} /></Typography>
                <ImageRenderer images={faq.images} />
                {userData.isAdmin && (
                  <Box sx={{ mt: 2 }}>
                    <IconButton onClick={() => handleFaqDialogOpen(faq)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleFaqDelete(faq.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ))}

      <Typography variant="h6" gutterBottom>
        お問い合わせ
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginTop: 40, border: '1px solid #ccc', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', padding: 20, borderRadius: 8 }}>
        <FormControl fullWidth margin="normal">
          <InputLabel>カテゴリ</InputLabel>
          <Select
            value={category}
            label="カテゴリ"
            onChange={e => setCategory(e.target.value)}
            required
          >
            {categories.map((category, index) => (
              <MenuItem key={index} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" gutterBottom>
          返信先メールアドレス
        </Typography>
        <FormControl component="fieldset" margin="normal">
          <RadioGroup
            value={useDefaultEmail ? 'default' : 'custom'}
            onChange={e => setUseDefaultEmail(e.target.value === 'default')}
          >
            <FormControlLabel value="default" control={<Radio />} label={`ログインメールアドレス (${userData.email}) を使用`} />
            <FormControlLabel value="custom" control={<Radio />} label="別のメールアドレスを使用" />
          </RadioGroup>
        </FormControl>
        {!useDefaultEmail && (
          <TextField
            required
            fullWidth
            label="メールアドレス"
            value={replyEmail}
            onChange={e => setReplyEmail(e.target.value)}
            margin="normal"
          />
        )}
        <TextField
          required
          fullWidth
          label="お問い合わせ内容"
          multiline
          rows={4}
          value={message}
          onChange={e => setMessage(e.target.value)}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          送信
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
          <Typography sx={{ mt: 2 }}>送信中...</Typography>
        </Backdrop>
      </form>
    </Container>
  );
}

export default SupportPage;
