import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";

const TaskItem = ({ taskName, isCompleted, taskDescription, Icon, link, onClick }) => {
  const navigate = useNavigate(); // ナビゲーションフックを宣言

  // ナビゲーションハンドラー
  const handleNavigation = () => {
    if (onClick) {
      onClick();
    } else if (link) {
      navigate(link, { state: { fromTask: taskName } });
    }
  };

  return (
    <>
      {isCompleted ? (
        <div className="flex items-center text-gray-400 p-2 rounded-md">
          <CheckCircleOutlineIcon className="text-green-200 mr-2 " />
          <span className="text-sm ">{taskName}</span>
        </div>
      ) : (
        <div
          className="flex hover:bg-gray-100 cursor-pointer duration-150 rounded-md p-2"
          onClick={handleNavigation}
        >
          <Icon className="text-gray-500 mr-2" />
          <div>
            <div className="font-bold text-gray-500 ">{taskName}</div>
            <div className=" text-sm text-gray-500">{taskDescription}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskItem;
