import { createContext, memo, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../api/firebase";
import CircularProgress from '@mui/material/CircularProgress';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Modalプロバイダ
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    content: "",
  });

  const toggleModal = (isOpen, title, content) => {
    setModal({
      isOpen: isOpen,
      title: title,
      content: content,
    });
  };

  const [snackState, setSnackState] = useState({
    isOpen: false, //boolean型で表示、非表示を切り替える
    type: "", //success, infoなどの背景色を変えるもの
    message: "", //中に入れるメッセージ内容
  });
  const toggleSnack = (isOpen, type, message) => {
    setSnackState({
      isOpen: isOpen,
      type: type,
      message: message,
    });
  };

// console.log("user =>",user)


  
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
  }, []);

  if (loading) {
    // return <div>読込中...</div>;

    return (
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="text-white flex flex-col items-center">
          <CircularProgress color="inherit" />
          <p>
        読込中
          </p>
        </div>
      </div>
    );


  }

  const value = {
    user,
    modal,
    setModal,
    snackState,
    toggleSnack,
    toggleModal,
  };

  return <AuthContext.Provider {...{ value }}>{children}</AuthContext.Provider>;
};

export default memo(AuthProvider);
