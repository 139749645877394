import React from "react";
import ToDoList from "./ToDoList";
import Music from "./Music";
import AudioPlayer from "./AudioPlayer";

const RightSections = () => {
  return (
    <>
      <ToDoList />
      {/* <AudioPlayer /> */}
    </>
  );
};

export default RightSections;
