import React, { useState,useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";



const UserVerification = () => {
  const [uids, setUids] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [verifiedCount, setVerifiedCount] = useState(0);
  const navigate = useNavigate(); // React RouterのuseNavigateフックを使用
  const [isAdmin, setIsAdmin] = useState(false); // 管理者フラグ

  useEffect(() => {
    const checkAdmin = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin);
        if (!userData.isAdmin) {
          navigate('/'); // 管理者でない場合はホームページにリダイレクト
        }
      } else {
        navigate('/'); // ユーザー情報が存在しない場合もホームページにリダイレクト
      }
    };

    checkAdmin();
  }, [navigate]);


  const handleInputChange = (e) => {
    setUids(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const uidList = uids.split("\n").map((uid) => uid.trim());
    let count = 0;

    for (const uid of uidList) {
      const userQuery = query(collection(db, "users"), where("uid", "==", uid));
      const userSnapshot = await getDocs(userQuery);

      for (const userDoc of userSnapshot.docs) {
        const userData = userDoc.data();
        if (userData.isVerified !== true) {
          await updateDoc(doc(db, "users", userDoc.id), {
            isVerified: true,
          });
          count += 1;
        }
      }
    }

    setVerifiedCount(count);
    setLoading(false);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        ユーザー認証
      </Typography>
      <TextField
        label="ユーザーUID"
        placeholder="ユーザーのUIDを改行区切りで入力してください"
        multiline
        rows={10}
        fullWidth
        variant="outlined"
        value={uids}
        onChange={handleInputChange}
        margin="normal"
      />
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          送信
        </Button>
      </Box>
      {loading && (
        <Box display="flex" justifyContent="center" mt={2} alignItems="center">
          <CircularProgress />
          <Typography variant="body1" ml={2}>
            認証処理中...
          </Typography>
        </Box>
      )}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>認証結果</DialogTitle>
        <DialogContent>
          <Typography>認証を完了したユーザー: {verifiedCount} 人</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserVerification;
