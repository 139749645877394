import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "600px", // 最大幅を600pxに設定
    width: "100%", // 幅を最大にする
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100% - 32px)", // 小さいデバイスの場合、幅いっぱいになるように設定
    },
  },
}));
const CustomCard = styled(Card)(({ theme }) => ({
  maxWidth: 556,
  marginTop: theme.spacing(2),
  position: "relative",
  boxShadow: theme.shadows[3],
  "&:hover": {
    boxShadow: theme.shadows[10],
  },
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.grey[500],
  "&:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[700],
  },
}));

const SelfIntroduction = () => {
  const [user, setUser] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editText, setEditText] = useState("");

  useEffect(() => {
    if (!auth.currentUser) return;

    const userDocRef = doc(db, "users", auth.currentUser.uid);

    // `onSnapshot` を使ってドキュメントをリアルタイムで監視
    const unsubscribe = onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        setUser(userData);
        setEditText(userData.selfIntroduction || "");
      } else {
        // ドキュメントが存在しない場合のハンドリング
        console.log("ドキュメントが見つかりませんでした。");
      }
    });

    // コンポーネントがアンマウントされる時に監視を解除
    return () => unsubscribe();
  }, []);

  const handleEditDialogToggle = () => {
    setEditDialogOpen(!editDialogOpen);
  };

  const handleTextChange = (event) => {
    setEditText(event.target.value);
  };

  const saveIntroduction = () => {
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    updateDoc(userDocRef, { selfIntroduction: editText }).then(() => {
      setUser({ ...user, selfIntroduction: editText });
      handleEditDialogToggle();
    });
  };

  const [showMore, setShowMore] = useState(false); // もっと見るボタンの状態
  const [isOverflowing, setIsOverflowing] = useState(false); // テキストがオーバーフローしているかどうか

  // Typographyの要素を参照するためのref
  const textRef = React.useRef(null);

  const checkOverflow = () => {
    // currentが存在することを確認し、scrollHeightとclientHeightを比較
    if (textRef.current) {
      setIsOverflowing(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  };

  useEffect(() => {
    // テキストの高さをチェックする関数を呼び出す
    checkOverflow();
    // ウィンドウサイズが変更されたときにもチェックを行うようにする
    window.addEventListener('resize', checkOverflow);
    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [user]); // ユーザ情報が変わったときに再計算


  return (
    <CustomCard>
      <EditButton onClick={handleEditDialogToggle}>
        <EditIcon />
      </EditButton>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          自己紹介
        </Typography>
        <Typography
          ref={textRef}
          variant="body1"
          component="p"
          sx={{
            whiteSpace: "pre-wrap",
            cursor: "pointer",
            maxHeight: showMore ? "none" : "120px", // showMoreがtrueなら制限なし、falseなら120px
            overflow: "hidden",
            textOverflow: "ellipsis", // テキストがはみ出したときに省略記号を表示
          }}
          onClick={handleEditDialogToggle}
        >
          {user
            ? user.selfIntroduction || "自己紹介を追加してください。"
            : "ローディング中..."}
        </Typography>
        {isOverflowing && !showMore && (
          <Button onClick={() => setShowMore(true)}>もっと表示する</Button>
        )}
      </CardContent>
      {/* 編集のダイアログ */}
      <StyledDialog
        open={editDialogOpen}
        onClose={handleEditDialogToggle}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">自己紹介を編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="selfIntroduction"
            label="自己紹介"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={editText}
            onChange={handleTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogToggle} color="primary">
            キャンセル
          </Button>
          <Button onClick={saveIntroduction} color="primary">
            保存
          </Button>
        </DialogActions>
      </StyledDialog>
    </CustomCard>
  );
};

export default SelfIntroduction;
