import React from "react";
import DDPSetting from "./DDPSetting";

const DDP = () => {
  return (
    <>
      <DDPSetting />
    </>
  );
};

export default DDP;
