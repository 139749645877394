import React, { useEffect, useState } from "react";
import {
  Chat,
  Channel,
  MessageList,
  MessageInput,
  Window,
} from "stream-chat-react";
import { useStreamChatClient } from "../context/StreamChatProvider";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "stream-chat-react/dist/css/index.css";

const GroupChatEx = ({ groupId }) => {
  const chatClient = useStreamChatClient();
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);

  const logErrorToFirestore = async (error, additionalInfo = {}) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        const db = getFirestore();
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        const errorDetails = {
          errorMessage: error.message,
          errorStack: error.stack,
          ...additionalInfo,
          userId: user.uid,
          userInfo: userDoc.exists() ? userDoc.data() : {},
          userAgent: navigator.userAgent,
          url: window.location.href,
          date: new Date().toISOString(),
        };

        await setDoc(
          doc(db, "errors", `${user.uid}_${Date.now()}`),
          errorDetails
        );
      } catch (firestoreError) {
        console.error("Error logging to Firestore:", firestoreError);
      }
    } else {
      console.error(
        "No authenticated user found. Error not logged to Firestore."
      );
    }
  };

  useEffect(() => {
    const initChat = async () => {
      try {
        if (chatClient) {
          const newChannel = chatClient.channel("messaging", groupId, {
            name: `Group Chat ${groupId}`,
          });

          // メッセージのクエリを設定して最新20件を取得
          const response = await newChannel.query({
            messages: { limit: 20, sort: [{ created_at: -1 }] },
          });

          await newChannel.watch();
          setChannel(newChannel);
          setMessages(response.messages.reverse()); // メッセージの順序を逆にして最新のメッセージが下に来るようにする
        }
      } catch (error) {
        await logErrorToFirestore(error, { functionName: "initChat" });
      }
    };
    initChat();
  }, [chatClient, groupId]);

  if (!chatClient || !channel) return <div>Loading chat...</div>;

  return (
    <Chat client={chatClient}>
      <Channel channel={channel}>
        <Window>
          <MessageList/>
          <MessageInput focus />
        </Window>
      </Channel>
    </Chat>
  );
};

export default GroupChatEx;
