import React, { useState } from "react";

const PostContent = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const showMoreButton = content.length > 300;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  function linkify(text) {
    const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
            {part}
          </a>
        );
      }
      return part;
    });
  }

  const formattedContent = isExpanded || content.length <= 300 ? linkify(content) : linkify(content.substring(0, 300) + "...");

  return (
    <div className="py-2">
      <p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
        {formattedContent}
      </p>
      {showMoreButton && (
        <button onClick={toggleExpand} style={{ display: 'block', margin: '10px 0',color:"blue" }}>
          {isExpanded ? '閉じる' : 'もっと表示する'}
        </button>
      )}
    </div>
  );
};

export default PostContent;
