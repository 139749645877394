import React, { useState, useEffect } from "react";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom"; // Import useLocation

const PurposeSetting = ({ userId }) => {
  const location = useLocation(); // Get the current location
  const isAdminPage = location.pathname === "/admin/userlist"; // Check if the current path is /admin/userlist

  const [purpose, setPurpose] = useState("");
  const [canChangePurpose, setCanChangePurpose] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [timerVisible, setTimerVisible] = useState(true);

  const changeDeadline = dayjs("2024-07-14T23:59:00").valueOf();

  useEffect(() => {
    if (!userId) return;

    const unsubscribe = onSnapshot(doc(db, "users", userId), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setPurpose(data.purpose || "");
        if (!isAdminPage) {
          const now = dayjs().valueOf();
          if (now > changeDeadline) {
            setCanChangePurpose(false);
            setTimerVisible(false);
          }
          setTimeLeft(changeDeadline - now);
        }
      }
    });

    return () => unsubscribe();
  }, [userId, changeDeadline, isAdminPage]);

  useEffect(() => {
    if (!isAdminPage) {
      const timer = setInterval(() => {
        const now = dayjs().valueOf();
        const diff = changeDeadline - now;
        setTimeLeft(diff);
        if (diff <= 0) {
          setCanChangePurpose(false);
          setTimerVisible(false);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [changeDeadline, isAdminPage]);

  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${days}日 ${hours}時間 ${minutes}分 ${secs}秒`;
  };

  const handlePurposeChange = async (event) => {
    const newPurpose = event.target.value;
    setPurpose(newPurpose);
    try {
      await updateDoc(doc(db, "users", userId), { purpose: newPurpose });
      setSnackbarMessage("利用目的を更新しました。");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating purpose", error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className="my-5"></div>
      <Accordion expanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="h6">利用目的</Typography>
            {purpose === "音源のダウンロードのみ" && (
              <Typography variant="body2" color="error" sx={{ marginLeft: 2 }}>
                現在、マスターマインドグループに参加しない設定になっています。
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="caption" display="block" gutterBottom>
            利用目的は2024/7/14 23:59までは変更が可能です。それ以降は変更できなくなります。
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            ※グループ参加ご希望の方は、居住地も併せて登録されないとグループに参加できません。
          </Typography>

          {timerVisible && !isAdminPage && (
            <Box mt={2} mb={2}>
              <Divider />
              <Typography variant="body1" align="center" mt={1}>
                残り時間: {formatTime(timeLeft)}
              </Typography>
              <Divider />
            </Box>
          )}
          <RadioGroup row name="purpose" value={purpose} onChange={handlePurposeChange} disabled={!canChangePurpose}>
            <FormControlLabel value="音源のダウンロードのみ" control={<Radio />} label="音源のダウンロードのみ" disabled={!canChangePurpose} />
            <FormControlLabel value="音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える" control={<Radio />} label="音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える" disabled={!canChangePurpose} />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message={snackbarMessage} />
    </>
  );
};

export default PurposeSetting;
