import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
} from "@mui/material";
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '600px', // 最大幅を600pxに設定
    width: '100%', // 幅を最大にする
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 32px)', // 小さいデバイスの場合、幅いっぱいになるように設定
    },
  },
}));

const EditNameDialog = ({ open, handleClose, handleSave, name }) => {
  const [userName, setUserName] = useState(name);

  const onSave = () => {
    handleSave(userName);
    handleClose();
  };
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>名前を編集してください。</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="名前"
          type="text"
          fullWidth
          variant="outlined"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={onSave} color="primary">
          保存
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default EditNameDialog;
