// PurposeComponent.js
import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Typography from "@mui/material/Typography";

const PurposeComponent = () => {
  const [purpose, setPurpose] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribeFromSnapshot = null;

    const fetchPurpose = () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        unsubscribeFromSnapshot = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            setPurpose(doc.data().purpose || "利用目的が設定されていません。");
          } else {
            setPurpose("No such document!");
          }
          setLoading(false);
        });
      } else {
        setPurpose("User not logged in");
        setLoading(false);
      }
    };

    const unsubscribeFromAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchPurpose();
      } else {
        setPurpose("User not logged in");
        setLoading(false);
      }
    });

    return () => {
      if (unsubscribeFromSnapshot) {
        unsubscribeFromSnapshot();
      }
      unsubscribeFromAuth();
    };
  }, []);

  if (loading) {
    return (
      <div className="text-center py-8">
        <p className="text-xl font-semibold">Loading...</p>
      </div>
    );
  }

  return (
    <div className="border rounded-md mb-2 p-2">
      <div className="flex flex-col items-center justify-center">
        <div className="text-2xl font-bold text-center">現在の設定：</div>
        <div className="text-white text-lg text-center bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded-full inline-block">
          {purpose}
        </div>
      </div>
      {purpose === "音源のダウンロードのみ" && (
        <Typography
          variant="body2"
          color="error"
          sx={{ marginLeft: 2, textAlign: "center"}}
        >
          現在、マスターマインドグループに参加しない設定になっています。
        </Typography>
      )}
    </div>
  );
};

export default PurposeComponent;
