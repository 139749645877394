import React, { useEffect } from "react";
import { Button, IconButton, Box, Typography, SvgIcon } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { auth, db } from "../api/firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  addDoc,
  collection,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { StreamChat } from "stream-chat";

// GoogleアイコンのSVGコンポーネント
const GoogleIcon = () => (
  <SvgIcon viewBox="0 0 48 48" style={{ display: "block" }}>
    <path
      fill="#EA4335"
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
    ></path>
    <path
      fill="#4285F4"
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
    ></path>
    <path
      fill="#FBBC05"
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
    ></path>
    <path
      fill="#34A853"
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
    ></path>
  </SvgIcon>
);

// スタイリッシュなボタンのスタイル定義
const StyledGoogleLoginButton = styled(Button)({
  color: "#5F6368",
  backgroundColor: "white",
  boxShadow: "none",
  border: "1px solid #dadce0",
  borderRadius: "24px", // 角を丸くする
  fontWeight: "bold",
  fontSize: "0.875rem",
  fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
  textTransform: "none",
  height: "40px",
  "&:hover": {
    boxShadow: "0 1px 3px rgb(0 0 0 / 30%)",
    backgroundColor: "#f5f5f5",
  },
  "& .MuiSvgIcon-root": {
    width: "24px",
    height: "24px",
  },
  "& .MuiButton-startIcon": {
    margin: 0,
    marginRight: "10px",
  },
  "& .MuiButton-label": {
    justifyContent: "space-between",
  },
});

// モーダルのスタイル定義
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px", // モーダルの角を丸くする
};

const LoginModalContent = ({ onClose, open }) => {
  const navigate = useNavigate();

  // Googleログインの処理
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);
  };

  useEffect(() => {
    let isMounted = true;
    const getRedirectGoogle = async () => {
      if (isMounted && !open) {
        try {
          const result = await getRedirectResult(auth);
          if (result) {
            const user = result.user;
            const inUser = await getDoc(doc(db, "users", user.uid));
            if (inUser.exists()) {
              await updateDoc(doc(db, "users", result.user.uid), {
                isOnline: true,
                isLogin: true,
                lastLogin: serverTimestamp(),
              });

              // ログイン情報追加
              await addDoc(
                collection(db, "users", result.user.uid, "loginHistory"),
                {
                  uid: result.user.uid,
                  loggedIn: serverTimestamp(),
                  userAgent: navigator.userAgent,
                }
              );
              await addDoc(collection(db, "loginHistory"), {
                uid: result.user.uid,
                loggedIn: serverTimestamp(),
                userAgent: navigator.userAgent,
              });

              // ログイン回数カウントアップ
              let loginCount = await getDoc(
                doc(db, "users", result.user.uid)
              ).then((doc) => doc.data().loginCount);
              // loginCountがなかったり「NaN」の場合、1
              if (isNaN(loginCount) === true) {
                loginCount = 1;
              }
              await updateDoc(doc(db, "users", result.user.uid), {
                loginCount: loginCount + 1,
              });

              navigate("/home");
              console.log("登録済、isOnline更新、ホーム画面遷移");
            } else {
              console.log("登録開始");
              await setDoc(doc(db, "users", result.user.uid), {
                uid: result.user.uid,
                name: user.displayName,
                email: user.email,
                avatar: user.photoURL,
                loginCount: 1,
                avatarPath: "",
                createdAt: serverTimestamp(),
                isOnline: true,
                isLogin: true,
                isStop: false,
              });

              navigate("/terms", {
                state: {
                  firstName: result._tokenResponse.firstName,
                  lastName: result._tokenResponse.lastName,
                  email: user.email,
                  uid: result.user.uid,
                },
              });
              console.log("初登録、firestore新規登録、ようこそ画面へ遷移");
            }
          }
        } catch (error) {
          console.error('認証結果の取得に失敗しました:', error);

          const result = await getRedirectResult(auth);
          const errorData = {
            message: error.message,
            stack: error.stack,
            user: result ? {
              uid: result.user.uid,
              email: result.user.email,
              displayName: result.user.displayName,
            } : null,
          };

          await addDoc(collection(db, "error"), {
            createdAt: serverTimestamp(),
            error: errorData,
          });

          console.log(error);
        }
      }
    };

    getRedirectGoogle();
    return () => {
      isMounted = false;
    };
  }, [open]);

  return (
    <Box sx={style}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          zIndex: 1, // zIndexを設定してボタンが上に来るようにする
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        variant="h5"
        component="h2"
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        MasterMindへようこそ！
      </Typography>
      {/* Googleログインボタン */}
      <StyledGoogleLoginButton
        fullWidth
        onClick={handleGoogleLogin}
        startIcon={<GoogleIcon />}
      >
        Googleで続ける
      </StyledGoogleLoginButton>
      <Typography
        variant="body2"
        sx={{ mt: 2, textAlign: "center", fontSize: "0.75rem" }}
      >
        続行することでMasterMindの
        <a
          href="/termsofservice"
          style={{ textDecoration: "none", color: "#0a66c2" }}
          target="_blank"
        >
          利用規約
        </a>
        に同意し、
        <a
          href="/privacypolicy"
          style={{ textDecoration: "none", color: "#0a66c2" }}
          target="_blank"
        >
          プライバシーポリシー
        </a>
        を読んだものとみなされます。
      </Typography>
    </Box>
  );
};

export default LoginModalContent;
