import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../api/firebase";
import {
  TextField,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import debounce from "lodash.debounce";

const GroupsList = () => {
  const [groups, setGroups] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [noMoreGroups, setNoMoreGroups] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const loadMoreRef = useRef(null);

  // 初期の10件をロード
  useEffect(() => {
    const fetchInitialGroups = async () => {
      setLoading(true);
      const groupsRef = collection(db, "groups");
      const q = query(groupsRef, orderBy("name"), limit(10));
      const querySnapshot = await getDocs(q);
      
      const groupsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setGroups(groupsData);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setLoading(false);
    };

    fetchInitialGroups();
  }, []);

  // スクロールしてさらにデータをロード
  const fetchMoreGroups = async () => {
    if (!lastVisible || loadingMore || noMoreGroups || searchTerm.trim() !== "") return;

    setLoadingMore(true);
    const groupsRef = collection(db, "groups");
    const q = query(
      groupsRef,
      orderBy("name"),
      startAfter(lastVisible),
      limit(20)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      setNoMoreGroups(true);
      setLoadingMore(false);
      return;
    }

    const newGroups = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    setGroups(prevGroups => [...prevGroups, ...newGroups]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoadingMore(false);
  };

  // 検索処理
  const fetchFilteredGroups = async (term, signal) => {
    if (term.trim() === "") {
      setGroups([]);
      setNoMoreGroups(false);
      setLastVisible(null);
      setSearchTerm("");
      setLoading(false);
      return;
    }

    setLoading(true);
    const groupsRef = collection(db, "groups");
    const q = query(
      groupsRef,
      orderBy("name"),
      startAfter(term),
    //   limit(10)
    );

    try {
      const querySnapshot = await getDocs(q, { signal });
      if (signal.aborted) return;
    
      const groupsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      setGroups(groupsData);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setLoading(false);
    } catch (error) {
      if (signal.aborted) return;
      console.error("Failed to fetch groups:", error);
      setLoading(false);
    }
  };

  const debouncedFetchFilteredGroups = debounce((term, signal) => fetchFilteredGroups(term, signal), 500);

  useEffect(() => {
    if (abortController) {
      abortController.abort(); // 前回のクエリをキャンセル
    }

    const controller = new AbortController();
    setAbortController(controller);

    if (searchTerm.trim() !== "") {
      debouncedFetchFilteredGroups(searchTerm, controller.signal);
    } else if (searchTerm.trim() === "") {
      setGroups([]);
      setLastVisible(null);
    }

    return () => {
      controller.abort(); // クリーンアップ時にクエリをキャンセル
      debouncedFetchFilteredGroups.cancel();
    };
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Intersection Observerを使用してロードトリガーを監視
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          fetchMoreGroups();
        }
      },
      {
        threshold: 0.9, // 要素が90%表示されたら
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [lastVisible]);

  const handleEditClick = (group) => {
    setEditingGroup(group);
    setNewGroupName(group.name);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditingGroup(null);
    setNewGroupName("");
  };

  const handleGroupNameChange = async () => {
    if (!editingGroup) return;

    const groupRef = doc(db, "groups", editingGroup.id);
    await updateDoc(groupRef, {
      name: newGroupName,
    });

    setGroups(groups.map(group => 
      group.id === editingGroup.id ? { ...group, name: newGroupName } : group
    ));
    
    handleDialogClose();
  };

  return (
    <Container>
      <Paper style={{ position: "sticky", top: 60, zIndex: 1, padding: "1rem", backgroundColor: "#fff" }}>
        <TextField
          label="グループ検索"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          disabled={loading}
        />
      </Paper>
      <List>
        {loading ? (
          <Typography variant="body1" style={{ padding: "1rem" }}>
            読み込み中...
          </Typography>
        ) : groups.length > 0 ? (
          groups.map(group => (
            <ListItem key={group.id} style={{ width: '100%' }}>
              <Box 
                sx={{ 
                  border: '1px solid gray', 
                  borderRadius: '8px', 
                  padding: '16px', 
                  marginBottom: '16px',
                  width: '100%',
                  transition: 'box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  },
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center">
                      {group.name}
                      <IconButton 
                        size="small" 
                        onClick={() => handleEditClick(group)}
                        sx={{ marginLeft: '8px' }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  }
                  secondary={`Created At: ${new Date(group.createdAt.seconds * 1000).toLocaleDateString()}`}
                />
                <Typography 
                  variant="body2" 
                  sx={{ 
                    cursor: 'pointer', 
                    color: 'gray', 
                    '&:hover': {
                      color: 'blue', 
                      fontStyle: 'italic'
                    }
                  }}
                  onClick={() => window.open(`/group/${group.id}`, '_blank')}
                >
                  {group.id}
                </Typography>
              </Box>
            </ListItem>
          ))
        ) : searchTerm === "" ? (
          <Typography variant="body1" style={{ padding: "1rem" }}>
            グループ名で検索してください。
          </Typography>
        ) : (
          <Typography variant="body1" style={{ padding: "1rem" }}>
            グループが見つかりません。
          </Typography>
        )}
        <div ref={loadMoreRef}></div>
      </List>
      {loadingMore && searchTerm === "" && (
        <Typography variant="body2" style={{ padding: "1rem" }}>
          読み込み中...
        </Typography>
      )}

      {/* 編集用ダイアログ */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>グループ名の編集</DialogTitle>
        <DialogContent>
          <DialogContentText>
            新しいグループ名を入力してください。
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="グループ名"
            type="text"
            fullWidth
            variant="outlined"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleGroupNameChange} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GroupsList;
