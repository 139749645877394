import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../api/firebase';
import { Box, Typography, Switch, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, Card, CardContent, MenuItem, Select } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const SettingsGroup = () => {
  const [userSettings, setUserSettings] = useState({
    showBloodtype: true,
    showSextype: true,
    bloodtype: '',
    sextype: '',
  });
  const [open, setOpen] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchUserSettings = async () => {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        setUserSettings(userSnap.data());
      }
    };

    fetchUserSettings();
  }, []);

  const handleSaveSettings = async () => {
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, userSettings);
    setOpen(false);
    setSnackbarOpen(true);
  };

  const handleChange = (event) => {
    setUserSettings({
      ...userSettings,
      [event.target.name]: event.target.value,
    });
  };

  const handleSwitchChange = async (event) => {
    const { name, checked } = event.target;
    const updatedSettings = {
      ...userSettings,
      [name]: checked,
    };
    setUserSettings(updatedSettings);

    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, { [name]: checked });
    setSnackbarOpen(true);
  };

  const handleClickOpen = (field) => {
    setCurrentField(field);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center"  >
      <Card variant="outlined" sx={{ width: '100%', maxWidth: 600, marginBottom: 2 }}>
        <CardContent sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>血液型</Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>血液型を公開</Typography>
            <Switch
              checked={userSettings.showBloodtype}
              onChange={handleSwitchChange}
              name="showBloodtype"
              color="primary"
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
            <Typography>血液型: {userSettings.bloodtype}</Typography>
            <IconButton onClick={() => handleClickOpen('bloodtype')} color="primary">
              <EditIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ width: '100%', maxWidth: 600, marginBottom: 2 }}>
        <CardContent sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>性別</Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>性別を公開</Typography>
            <Switch
              checked={userSettings.showSextype}
              onChange={handleSwitchChange}
              name="showSextype"
              color="primary"
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
            <Typography>性別: {userSettings.sextype}</Typography>
            <IconButton onClick={() => handleClickOpen('sextype')} color="primary">
              <EditIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>設定を編集</DialogTitle>
        <DialogContent>
          {currentField === 'bloodtype' ? (
            <Select
              label="血液型"
              name="bloodtype"
              value={userSettings.bloodtype}
              onChange={handleChange}
              fullWidth
              margin="dense"
            >
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="O">O</MenuItem>
              <MenuItem value="AB">AB</MenuItem>
            </Select>
          ) : (
            <Select
              label="性別"
              name="sextype"
              value={userSettings.sextype}
              onChange={handleChange}
              fullWidth
              margin="dense"
            >
              <MenuItem value="男性">男性</MenuItem>
              <MenuItem value="女性">女性</MenuItem>
              <MenuItem value="回答しない">回答しない</MenuItem>
            </Select>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button variant="contained" onClick={handleSaveSettings} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="完了しました"
      />
    </Box>
  );
};

export default SettingsGroup;
