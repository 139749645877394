import React, { useState } from "react";
import { Container, List, ListItem, ListItemText, TextField, Button } from "@mui/material";
import { functions, httpsCallable } from "../api/firebase"; // Firebaseの設定をインポート

function ChatInterface() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  // Firebase Functionsを呼び出してChatGPTとの対話を行う関数を更新
  const sendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { text: input, sender: "user" };
    const updatedMessages = [...messages, userMessage];
    setInput("");

    const chatWithGPT = httpsCallable(functions, 'chatWithGPT');
    try {
      const { data } = await chatWithGPT({ prompt: input });
      const aiMessage = { text: data.response, sender: "ai" };
      setMessages([...updatedMessages, aiMessage]);
    } catch (error) {
      console.error("Error calling Firebase Function:", error);
      setMessages([...updatedMessages, { text: "Error: Could not get response from the server.", sender: "ai" }]);
    }
  };

  return (
    <Container>
      <List>
        {messages.map((message, index) => (
          <ListItem key={index}>
            <ListItemText primary={message.text} secondary={message.sender === "ai" ? "AI" : "You"} />
          </ListItem>
        ))}
      </List>
      <TextField
        fullWidth
        variant="outlined"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && sendMessage()}
        placeholder="Type your message..."
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={sendMessage}>
        Send
      </Button>
    </Container>
  );
}

export default ChatInterface;
