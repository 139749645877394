import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const NotVerified = () => {
  const navigate = useNavigate();

  const handleSupportClick = () => {
    navigate("/supportpage");
  };

  return (
    <div>
      <div>
        現在ユーザ認証がないためコンテンツの利用ができません。
        認証作業が終わるまでお待ち下さい。
      </div>
      <Button variant="contained" color="primary" onClick={handleSupportClick}>
        お問い合わせ
      </Button>
    </div>
  );
};

export default NotVerified;

