import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Music from "./Music";
import GroupingTest from "./GroupingTest";
import ContactRequests from "./ContactRequests";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../api/firebase";
import UsersList from "./UsersList";
import UserVerification from "./UserVerification";

const drawerWidth = 240;

const AdminSetting = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [unresolvedCount, setUnresolvedCount] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchUnresolvedCount = async () => {
      const querySnapshot = await getDocs(collection(db, "support"));
      const requestsData = querySnapshot.docs.map((doc) => doc.data());
      const unresolvedRequests = requestsData.filter(
        (request) => request.isResolved === false
      );
      setUnresolvedCount(unresolvedRequests.length);
    };

    fetchUnresolvedCount();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setMobileOpen(false); // モバイルではメニューを閉じる
  };

  const drawer = (
    <div>
      <List>
        <ListItem
          button
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemText primary="音楽" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemText primary="グループ（テスト）" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography>お問い合わせ</Typography>
            <Typography>({unresolvedCount})</Typography>
          </Box>
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemText primary="ユーザー一覧" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemText primary="照合状況" />
        </ListItem>

        {/* 今後追加するコンテンツのリスト項目をここに追加 */}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {drawer}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {selectedIndex === 0 && <Music />}
        {selectedIndex === 1 && <GroupingTest />}
        {selectedIndex === 2 && <ContactRequests />}
        {selectedIndex === 3 && <UsersList />}
        {selectedIndex === 4 && <UserVerification />}
        {/* 追加するコンテンツの表示をここに追加 */}
      </Box>
    </Box>
  );
};

export default AdminSetting;
