import React, { useState, useEffect } from "react";
import { auth, db } from "../api/firebase";
import Img from "../data/defaultBack.png";
import { getDocs, collection, query, where } from "firebase/firestore";

import ProfileCard from "./ProfileCard";
import BackImage from "./BackImage";
import ProfilePicture from "./ProfilePicture";
import ProfileName from "./ProfileName";

import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import { memo } from "react";
import UserSetting from "./UserSetting";
import { useLocation, useNavigate } from "react-router-dom";
import SelfIntroduction from "./SelfIntroduction";

const Profile = memo(() => {
  return (
    <>
      <div className="flex flex-col w-full my-5">
        <div className="relative rounded-md">
          <BackImage id={auth.currentUser.uid} />
          <ProfilePicture id={auth.currentUser.uid} style={{ zIndex: 10 }} />
        </div>
        <ProfileName />
        <ProfileCard />
        <SelfIntroduction />
        {/* <div className="col-start-1 col-end-4">
          <UserSetting id={auth.currentUser.uid} />
        </div> */}
      </div>
    </>
  );
});

export default Profile;
