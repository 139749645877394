import React, { useEffect, useState } from "react";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import TaskItem from "./TaskItem"; // TaskItemコンポーネントをインポート
import GroupsIcon from "@mui/icons-material/Groups";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const ToDoList = () => {
  const [user, setUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openThankYouDialog, setOpenThankYouDialog] = useState(false);
  const [verificationDetails, setVerificationDetails] = useState({
    name: "",
    furigana: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    const userDocRef = doc(db, "users", auth.currentUser.uid);

    const unsubscribe = onSnapshot(userDocRef, (doc) => {
      const userData = doc.data();
      setUser(userData);
      if (userData && !userData.registrationName) {
        setOpenDialog(true);
      }
    });

    return unsubscribe;
  }, []);

  const handleVerificationClick = () => {
    if (user) {
      setVerificationDetails({
        name: user.registrationName || "",
        furigana: user.registrationFurigana || "",
        email: user.registrationEmail || "",
        phone: user.registrationPhone || "",
      });
    }
    setOpenDialog(true);
  };

  const handleConfirm = async () => {
    if (auth.currentUser) {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userDocRef, {
        registrationName: verificationDetails.name,
        registrationFurigana: verificationDetails.furigana,
        registrationEmail: verificationDetails.email,
        registrationPhone: verificationDetails.phone,
        isVerified: false, // 本人確認中に設定
      });
      setOpenDialog(false);
      setOpenThankYouDialog(true);
    }
  };

  const handleThankYouDialogClose = () => {
    setOpenThankYouDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVerificationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const isConfirmButtonDisabled =
    !verificationDetails.name ||
    !verificationDetails.furigana ||
    (!verificationDetails.email && !verificationDetails.phone);

  // タスクを未完了と完了に分けて整理
  const tasks = [
    {
      taskName: "居住県を登録する",
      isCompleted: user?.isRegisterPrefecture,
      taskDescription:
        "居住県を登録して近隣の人とマスターマインドを組む準備をしましょう。クリックするとプロフィール画面へ飛びます。",
      Icon: GroupsIcon,
      link: "/profile",
    },
    {
      taskName: "プロフィール画像を変更する",
      isCompleted: user?.isRegisterAvatar,
      taskDescription:
        "プロフィール画面のアバターをクリック(タッチ)してプロフィール画像を変更しましょう。クリックするとプロフィール画面に飛びます。",
      Icon: AccountCircleIcon,
      link: "/profile",
    },
    {
      taskName: "DDPを登録する",
      isCompleted: user?.isRegisterDDP,
      taskDescription:
        "マスターマインドと共に叶えるDDPを表明して、アイディアを共有し欠点と不足を補い合う準備をしよう。クリックするとDDP設定画面へ飛びます。",
      Icon: FavoriteBorderIcon,
      link: "/ddp",
    },
    {
      taskName: "本人確認する",
      isCompleted: user?.isVerified, // 本人確認が済んでいるかどうか
      taskDescription:
        user?.registrationName &&
        user?.registrationFurigana &&
        (user?.registrationEmail || user?.registrationPhone) &&
        !user?.isVerified
          ? "本人確認中です。認証が確認取れましたら「本人確認が取れました」という表示に変わります。"
          : "安全な環境を提供するために、本人確認を行いましょう。",
      Icon: VerifiedUserIcon,
      onClick:
        user?.registrationName &&
        user?.registrationFurigana &&
        (user?.registrationEmail || user?.registrationPhone) &&
        !user?.isVerified
          ? null // 本人確認中の場合はクリックを無効化
          : handleVerificationClick,
    },
  ];

  // タスクを未完了と完了に分ける
  const incompleteTasks = tasks.filter((task) => !task.isCompleted);
  const completedTasks = tasks.filter((task) => task.isCompleted);

  return (
    <>
      <Accordion defaultExpanded className="border shadow-xl">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>スターティングミッション</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="bg-white text-gray-800 space-y-1">
            {incompleteTasks.map((task) => (
              <TaskItem
                key={task.taskName}
                taskName={task.taskName}
                isCompleted={task.isCompleted}
                taskDescription={task.taskDescription}
                Icon={task.Icon}
                link={task.link}
                onClick={task.onClick} // タスクのonClickを設定
              />
            ))}
            {completedTasks.map((task) => (
              <TaskItem
                key={task.taskName}
                taskName={task.taskName}
                isCompleted={task.isCompleted}
                taskDescription={task.taskDescription}
                Icon={task.Icon}
                link={task.link}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={openDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>本人確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            マスターマインド登録時の情報を入力してください。
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            name="name"
            label="名前"
            type="text"
            fullWidth
            value={verificationDetails.name}
            onChange={handleInputChange}
          />
          <TextField
            required
            margin="dense"
            name="furigana"
            label="なまえ（ふりがな）"
            type="text"
            fullWidth
            value={verificationDetails.furigana}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="登録時のメールアドレス"
            placeholder="登録時のメールアドレス"
            type="email"
            fullWidth
            value={verificationDetails.email}
            onChange={handleInputChange}
          />
          <div className=" text-red-500 text-xs">※マスターマインドのURLの案内が届いたメールアドレスをご入力ください。</div>
          <TextField
            margin="dense"
            name="phone"
            label="登録時の電話番号"
            type="text"
            fullWidth
            value={verificationDetails.phone}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary" disabled={isConfirmButtonDisabled}>
            確認
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openThankYouDialog} onClose={handleThankYouDialogClose}>
        <DialogTitle>情報ありがとうございます</DialogTitle>
        <DialogContent>
          <DialogContentText>
            頂いた情報と登録時の情報を認証情報に利用させていただきます。
            認証確認できたらスターティングミッションの本人確認が済になります。
            よろしくお願い申し上げます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleThankYouDialogClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToDoList;
