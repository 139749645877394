import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useSpring, animated } from "react-spring";
import "../css/CelebrationAnimation.css";

export const CelebrationAnimation = ({ isOpen, setIsOpen, message }) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    let timer;

    if (isOpen) {
      setShowConfetti(true);
      // 5秒後に閉じるボタンを表示
      timer = setTimeout(() => setShowButton(true), 5000);
    } else {
      setShowConfetti(false);
      setShowButton(false);
    }

    // コンポーネントのクリーンアップ時にタイマーをクリアする
    return () => clearTimeout(timer);
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false); // isOpen を false に設定して背景を非表示に
    setShowConfetti(false); // 必要ならコンフェッティも非表示に
  };

  const animationProps = useSpring({
    opacity: showConfetti ? 1 : 0,
  });

  return (
    <div className={`dark-background ${isOpen ? "active" : ""}`}>
      {showConfetti && <Confetti />}

      <animated.div style={animationProps} className="border rounded-md">
        <div className="text-white text-xl p-5 flex flex-col items-center">
          <h1>🎁🎉おめでとうございます🎁🎉</h1>
          <p>{message}</p>
        </div>
      </animated.div>
      {showButton && (
        <div className="flex relative -top-10 right-6">
          <button className="close-button" onClick={handleClose}>
            ✗
          </button>
        </div>
      )}

    </div>
  );
};
