import React, { memo, useState, useEffect } from "react";
import { AuthContext, useAuth } from "../context/auth";
import { Navigate, Outlet } from "react-router-dom";
import { auth, db } from "../api/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Entrance from "./Entrance";

const PrivateRoute = () => {
  // const { user } = useContext(AuthContext);

  const { user } = useAuth();

  const userStatusUpdate = (status) => {
    const userRef = doc(db, "users", auth.currentUser.uid);
    const data = {
      isOnline: status,
    };
    user && updateDoc(userRef, data);
  };

  if (user) {
    console.log("ログイン中");
    // console.log("auth.currentUser.uid => ", auth.currentUser.uid);
    // console.log("navigator.userAgent => ", navigator.userAgent);
    // console.log("navigator.userAgentData => ", navigator.userAgentData);
  } else {
    console.log("ログアウト");
  }

  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
    // mobile
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        console.log("doc_コンテンツが表示された");
        // userStatusUpdate(true);
      }

      if (document.visibilityState === "hidden") {
        console.log("doc_コンテンツがバックグラウンドになった");
        // userStatusUpdate(false);
      }
    });
  } else {
    window.addEventListener("focus", () => {
      console.log("win_コンテンツが表示された");
      // userStatusUpdate(true);
    });
    window.addEventListener("blur", () => {
      console.log("win_コンテンツが非表示になった");
      // userStatusUpdate(false);
    });
  }



  if (!user) return <Navigate to="/welcome" />;

  return (
    <div className="">
      <Entrance outlet={<Outlet />} />
    </div>
  );
};

export default memo(PrivateRoute);
