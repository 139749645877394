import React, { useState, useEffect } from "react";
import { auth, db } from "../api/firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { onAuthStateChanged } from "firebase/auth";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { CelebrationAnimation } from "./CelebrationAnimation";
import DDPList from "./DDPList";
import DDPSubGoals from "./DDPSubGoals";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DDPSetting = () => {
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState("");
  const [run, setRun] = useState(false);
  const [hasDdps, setHasDdps] = useState(false);
  const [ddpId, setDdpId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (userId) {
      const ddpsRef = collection(db, `users/${userId}/ddps`);
      const unsubscribe = onSnapshot(ddpsRef, (querySnapshot) => {
        const ddpIds = [];
        querySnapshot.forEach((doc) => {
          ddpIds.push(doc.id);
        });
        setDdpId(ddpIds.length > 0 ? ddpIds[0] : null);
        setHasDdps(!querySnapshot.empty);
        setLoading(false);
      });
      return () => unsubscribe();
    } else {
      setLoading(false);
    }
  }, []);

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  const handleOpenModal = (event) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (!userId) {
      console.error("ユーザーがログインしていません");
      setLoading(false);
      return;
    }
    const userDoc = doc(db, "users", auth.currentUser.uid);
    const userDdpsRef = collection(db, `users/${userId}/ddps`);
    try {
      const querySnapshot = await getDocs(userDdpsRef);
      const isFirstRegistration = querySnapshot.empty;
      await addDoc(userDdpsRef, {
        ddp: inputText,
        createdAt: serverTimestamp(),
      });
      if (isFirstRegistration) {
        await updateDoc(userDoc, { isRegisterDDP: true });
        handleEvent("ddpRegistration");
      }
      setSnackbarOpen(true);
      setInputText("");
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };

  const handleEvent = async (eventType) => {
    setCelebrationMessage("DDPが登録されました！");
    setIsOpen(true);
  };

  const steps = [
    { target: ".text", content: "DDPを登録しましょう。" },
  ];

  useEffect(() => {
    if (user !== null) {
      const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
        const userData = doc.data();
        if (userData && (userData.isRegisterDDP === false || userData.isRegisterDDP === undefined)) {
          setRun(true);
        } else {
          setRun(false);
        }
      });
      return () => unsubscribe();
    }
  }, [user]);

  const handleJoyrideCallback = (data) => {
    const { action, status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    } else if (action === ACTIONS.CLOSE) {
      setRun(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          {!hasDdps && (
            <div className="bg-white border rounded-md mt-2 p-4 shadow-sm hover:shadow-lg cursor-pointer">
              <div className="font-bold text-lg">DDP設定</div>
              <form onSubmit={handleOpenModal}>
                <TextField
                  multiline
                  minRows={3}
                  placeholder="あなたのＤＤＰを登録してください。"
                  variant="outlined"
                  fullWidth
                  value={inputText}
                  onChange={handleChange}
                  style={{ margin: "10px 0" }}
                  disabled={loading}
                  className="text"
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading || !inputText}
                >
                  送信
                </Button>
                {loading && (
                  <CircularProgress size={24} style={{ marginLeft: 15 }} />
                )}
              </form>
            </div>
          )}

          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="flex flex-col" sx={modalStyle}>
              <div className=" font-bold text-lg" id="modal-modal-title">
                DDP登録
              </div>
              <p
                className=" overflow-y-auto max-h-60"
                id="modal-modal-description"
                style={{ wordBreak: "break-word" }}
              >
                {inputText}
              </p>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
              >
                登録
              </Button>
            </Box>
          </Modal>

          {user && <DDPList userId={user?.uid} />}
          <div className="my-3"></div>
          {/* {ddpId && <DDPSubGoals userId={user?.uid} ddpId={ddpId} />} */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            message="DDPの登録が完了しました"
          />

          <CelebrationAnimation
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            message={celebrationMessage}
          />

          <Joyride
            run={run}
            steps={steps}
            callback={handleJoyrideCallback}
            showSkipButton
            autoStart={true}
            spotlightPadding={10}
            styles={{
              options: {
                zIndex: 1000,
                spotlightBorderRadius: "10px",
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default DDPSetting;
