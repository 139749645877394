// StreamChatContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { auth, db } from "../api/firebase";
import { doc, getDoc } from "firebase/firestore";
export const StreamChatContext = createContext(null);

export const StreamChatProvider = ({ children }) => {
  const [chatClient, setChatClient] = useState(null);

  useEffect(() => {
    const connectChatClient = async (user) => {
      // console.log("user =>", user);
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      let userData;
      if (!userDocSnap.exists()) {
        console.error("User document does not exist in Firestore");
        userData = {
          uid: user?.uid,
          name: user?.displayName,
          avatar: user?.photoURL,
        };
      } else {
        userData = userDocSnap.data();
      }

      // console.log("userData =>", userData);
      // const token = await fetch(
      // "http://127.0.0.1:5001/mastermind-b120c/us-central1/token",
      // {
      const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: userData.uid,
        }),
      }).then((r) => r.json());

      if (!chatClient || chatClient.userID !== userData.uid) {
        const client = StreamChat.getInstance(
          process.env.REACT_APP_STREAM_API_KEY
        );
        await client.connectUser(
          {
            id: userData.uid,
            name: userData.name,
            image: userData.avatar,
          },
          token
        );
        setChatClient(client);
      }

      // console.log(
      //   "process.env.REACT_APP_STREAM_GET_TOKEN_URL =>",
      //   process.env.REACT_APP_STREAM_GET_TOKEN_URL
      // );
      // const response = await fetch(
      //   // "http://127.0.0.1:5001/mastermind-b120c/us-central1/token",
      //   process.env.REACT_APP_STREAM_GET_TOKEN_URL,

      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ input: userData.uid }),
      //   }
      // );

      // const tokenResponse = await response.json();
      // console.log('Token response:', tokenResponse); // トークンのレスポンスを確認

      // const token = tokenResponse.token; // 仮定するトークンのプロパティ名
      // if ((token && !chatClient) || chatClient?.userID !== userData.uid) {
      //   const client = StreamChat.getInstance(
      //     process.env.REACT_APP_STREAM_API_KEY
      //   );
      //   await client.connectUser(
      //     {
      //       id: userData.uid,
      //       name: userData.name,
      //       image: userData.avatar,
      //     },
      //     token // ここでトークンを渡す
      //   );
      //   setChatClient(client);
      // }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) connectChatClient(user);
    });

    return () => {
      unsubscribe();
      chatClient?.disconnectUser().then(() => setChatClient(null));
    };
  }, [chatClient]);

  return (
    <StreamChatContext.Provider value={chatClient}>
      {children}
    </StreamChatContext.Provider>
  );
};

export const useStreamChatClient = () => useContext(StreamChatContext);
