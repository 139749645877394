import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Snackbar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { CelebrationAnimation } from "./CelebrationAnimation";

const PrefectureDialog = ({ open, handleClose, onPrefectureUpdated }) => {
  const [selectedPrefecture, setSelectedPrefecture] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!open) {
      setSelectedPrefecture("");
      setIsCheckboxChecked(false);
      setError("");
    }
  }, [open]);

  const handleUpdate = async () => {
    if (!selectedPrefecture || !isCheckboxChecked) return;

    try {
      const userDoc = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userDoc, {
        prefecture: selectedPrefecture,
        isRegisterPrefecture: true,
      });
      onPrefectureUpdated("居住県が登録されました！");
      handleEvent("prefectureRegistration");
      handleClose();
    } catch (error) {
      console.error("Failed to update prefecture:", error);
      setError("居住地の更新に失敗しました。もう一度お試しください。");
    }
  };

  const handleEvent = (eventType) => {
    if (eventType === "prefectureRegistration") {
      setCelebrationMessage("おめでとうございます！居住県が登録されました！");
      setIsOpen(true);
      setTimeout(() => setIsOpen(false), 3000);
    }
  };

  const regions = {
    "北海道・東北": [
      "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県",
    ],
    "関東": [
      "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県",
    ],
    "中部": [
      "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県",
    ],
    "近畿": [
      "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県",
    ],
    "中国": [
      "鳥取県", "島根県", "岡山県", "広島県", "山口県",
    ],
    "四国": [
      "徳島県", "香川県", "愛媛県", "高知県",
    ],
    "九州・沖縄": [
      "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県",
    ],
    "海外": [
      "海外（アメリカ）", "海外（その他）",
    ]
  };

  const renderMenuItems = () => {
    return Object.entries(regions).flatMap(([region, prefectures]) => [
      <ListSubheader key={region}>{region}</ListSubheader>,
      ...prefectures.map((prefecture) => (
        <MenuItem value={prefecture} key={prefecture}>
          {prefecture}
        </MenuItem>
      )),
    ]);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>居住地を選択</DialogTitle>
      <DialogContent dividers style={{ paddingTop: 20, paddingBottom: 20 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>都道府県</InputLabel>
          <Select
            value={selectedPrefecture}
            onChange={(event) => setSelectedPrefecture(event.target.value)}
            label="都道府県"
            renderValue={(selected) => selected}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {renderMenuItems()}
          </Select>
        </FormControl>
        <CelebrationAnimation
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          message={celebrationMessage}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              color="primary"
            />
          }
          label="一度決定した居住地は変更できません。必ずご確認ください。"
          style={{ marginTop: 20 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          閉じる
        </Button>
        <Button
          onClick={handleUpdate}
          color="primary"
          variant="contained"
          disabled={!selectedPrefecture || !isCheckboxChecked}
        >
          更新
        </Button>
      </DialogActions>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}
        message={error}
      />
    </Dialog>
  );
};

export default PrefectureDialog;



