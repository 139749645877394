import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const BirthDayEditDialog = ({
  open,
  handleClose,
  handleSave,
  currentBirthDate,
}) => {
  const [birthDate, setBirthDate] = useState(currentBirthDate || "2000-01-01");

  const handleDateChange = (event) => {
    setBirthDate(event.target.value);
  };

  const saveBirthDate = () => {
    const [year, month, day] = birthDate.split("-");
    handleSave({ year, month, day });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>誕生日を編集</DialogTitle>
      <DialogContent>
        <TextField
          label="誕生日"
          type="date"
          fullWidth
          value={birthDate}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
            style: {
              // ラベルのスタイルを調整する
              fontSize: "1rem", // フォントサイズを大きくする
              color: "rgba(0, 0, 0, 0.6)", // フォントカラーを設定する
              // 他のスタイルプロパティ...
            },
          }}
          // TextField全体のスタイルもここで調整可能
          style={{
            margin: "8px 0", // 余白を調整する
            // 他のスタイルプロパティ...
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={saveBirthDate} color="primary">
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BirthDayEditDialog;
