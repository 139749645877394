import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Box
} from "@mui/material";
import { db, auth } from "../api/firebase";
import { collection, addDoc, getDocs, deleteDoc, doc, getDoc, updateDoc, query, where } from "firebase/firestore";
import AnnouncementItem from "./AnnouncementItem";
import { useNavigate } from "react-router-dom";
import PostContent from "./PostContent"; // インポート

const Announcement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState({
    title: "",
    content: "",
    isVisible: false,
  });
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const [announcementToDelete, setAnnouncementToDelete] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminView, setAdminView] = useState(true); // 管理者ビューのトグル
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnnouncements = async () => {
      let announcementsCol = collection(db, "announcements");
      if (!isAdmin || !adminView) {
        announcementsCol = query(announcementsCol, where("isVisible", "==", true));
      }
      const announcementSnapshot = await getDocs(announcementsCol);
      const announcementList = announcementSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      setAnnouncements(announcementList);
    };

    const fetchUserInfo = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin);
        if (userData.isAdmin) {
          fetchAnnouncements(); // 管理者の場合はすべてのお知らせを取得
        } else {
          fetchAnnouncements(); // 一般ユーザーの場合は表示非表示がtrueのお知らせを取得
        }
      }
    };

    fetchUserInfo();
  }, [isAdmin, adminView]);

  const handleAddAnnouncement = async () => {
    if (newAnnouncement.title.trim() === "" || newAnnouncement.content.trim() === "") return;

    const announcementsCol = collection(db, "announcements");
    const docRef = await addDoc(announcementsCol, {
      title: newAnnouncement.title,
      content: newAnnouncement.content,
      isVisible: newAnnouncement.isVisible,
      createdAt: new Date(),
    });

    const newAnnouncementData = {
      id: docRef.id,
      ...newAnnouncement,
      createdAt: new Date(),
    };
    setAnnouncements((prev) => [newAnnouncementData, ...prev]);
    setNewAnnouncement({
      title: "",
      content: "",
      isVisible: false,
    });
    setOpen(false);
  };

  const handleDeleteAnnouncement = async () => {
    if (announcementToDelete && announcementToDelete.id) {
      const docRef = doc(db, "announcements", announcementToDelete.id);
      await deleteDoc(docRef);

      setAnnouncements((prev) => prev.filter((announcement) => announcement.id !== announcementToDelete.id));
      setDeleteOpen(false);
      setAnnouncementToDelete(null); // リセット
    }
  };

  const handleVisibilityChange = async (id, isVisible) => {
    const docRef = doc(db, "announcements", id);
    await updateDoc(docRef, { isVisible });

    setAnnouncements((prev) =>
      prev.map((announcement) =>
        announcement.id === id ? { ...announcement, isVisible } : announcement
      )
    );
  };

  const handleEditAnnouncement = (announcement) => {
    setCurrentAnnouncement(announcement);
    setEditOpen(true);
  };

  const handleViewAnnouncement = (announcement) => {
    setCurrentAnnouncement(announcement);
    setViewOpen(true);
  };

  const handleUpdateAnnouncement = async () => {
    if (currentAnnouncement) {
      const docRef = doc(db, "announcements", currentAnnouncement.id);
      await updateDoc(docRef, {
        title: currentAnnouncement.title,
        content: currentAnnouncement.content,
      });

      setAnnouncements((prev) =>
        prev.map((announcement) =>
          announcement.id === currentAnnouncement.id
            ? { ...announcement, ...currentAnnouncement }
            : announcement
        )
      );
      setEditOpen(false);
      setCurrentAnnouncement(null);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setCurrentAnnouncement(null);
  };

  const handleViewClose = () => {
    setViewOpen(false);
    setCurrentAnnouncement(null);
  };

  const handleDeleteOpen = (announcement) => {
    setAnnouncementToDelete(announcement);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setAnnouncementToDelete(null); // リセット
  };

  return (
    <Card style={{ marginBottom: "16px", position: "relative" }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            事務局からのお知らせ
          </Typography>
          {isAdmin && (
            <FormControlLabel
              control={
                <Switch
                  checked={adminView}
                  onChange={(e) => setAdminView(e.target.checked)}
                  color="primary"
                />
              }
              label="管理者モード"
            />
          )}
        </Box>
        <List>
          {announcements.slice(0, 3).map((announcement) => (
            <AnnouncementItem
              key={announcement.id}
              announcement={announcement}
              onDelete={handleDeleteOpen}
              onEdit={handleEditAnnouncement}
              onView={handleViewAnnouncement}
              onVisibilityChange={handleVisibilityChange}
              isAdmin={isAdmin && adminView} // 管理者モードが有効の場合のみボタンを表示
            />
          ))}
        </List>
        {announcements.length > 3 && (
          <Typography
            variant="body2"
            color="primary"
            style={{ cursor: "pointer", position: "absolute", bottom: 8, right: 8 }}
            onClick={() => navigate('/announcements')}
          >
            もっと見る
          </Typography>
        )}
        {isAdmin && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            style={{ marginTop: "8px" }}
          >
            新しいお知らせ
          </Button>
        )}
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>新しいお知らせを追加</DialogTitle>
          <DialogContent>
            <DialogContentText>
              新しいお知らせの内容を入力してください。
            </DialogContentText>
            <FormControlLabel
              control={
                <Switch
                  checked={newAnnouncement.isVisible}
                  disabled
                  color="primary"
                />
              }
              label="表示"
            />
            <TextField
              autoFocus
              margin="dense"
              label="タイトル"
              type="text"
              fullWidth
              variant="outlined"
              value={newAnnouncement.title}
              onChange={(e) =>
                setNewAnnouncement({ ...newAnnouncement, title: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="内容"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={6}
              value={newAnnouncement.content}
              onChange={(e) =>
                setNewAnnouncement({
                  ...newAnnouncement,
                  content: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              キャンセル
            </Button>
            <Button onClick={handleAddAnnouncement} color="primary">
              追加
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={editOpen} onClose={handleEditClose} maxWidth="md" fullWidth>
          <DialogTitle>お知らせを編集</DialogTitle>
          <DialogContent>
            <DialogContentText>
              お知らせの内容を編集してください。
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="タイトル"
              type="text"
              fullWidth
              variant="outlined"
              value={currentAnnouncement?.title || ""}
              onChange={(e) =>
                setCurrentAnnouncement({
                  ...currentAnnouncement,
                  title: e.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              label="内容"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={6}
              value={currentAnnouncement?.content || ""}
              onChange={(e) =>
                setCurrentAnnouncement({
                  ...currentAnnouncement,
                  content: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="secondary">
              キャンセル
            </Button>
            <Button onClick={handleUpdateAnnouncement} color="primary">
              更新
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={viewOpen} onClose={handleViewClose} maxWidth="md" fullWidth>
          <DialogTitle>{currentAnnouncement?.title}</DialogTitle>
          <DialogContent>
            <PostContent content={currentAnnouncement?.content || ""} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewClose} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteOpen} onClose={handleDeleteClose}>
          <DialogTitle>お知らせの削除</DialogTitle>
          <DialogContent>
            <DialogContentText>
              本当にこのお知らせを削除しますか？ この操作は取り消せません。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="secondary">
              キャンセル
            </Button>
            <Button onClick={handleDeleteAnnouncement} color="primary">
              削除
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default Announcement;
