import React from "react";

const extractVimeoId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:video\/)?(\d+)(?:\?h=(\w+))?/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const extractYouTubeId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=([^&]+)|youtu\.be\/([^?&]+))/;
  const match = url.match(regex);
  return match ? match[1] || match[2] : null;
};

const cleanYouTubeUrl = (url) => {
  const videoId = extractYouTubeId(url);
  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
};

const convertToEmbedUrl = (url) => {
  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    return cleanYouTubeUrl(url);
  } else if (url.includes("vimeo.com")) {
    const videoId = extractVimeoId(url);
    const hashMatch = url.match(/(?:\?h=(\w+))/);
    // const hash = hashMatch ? hashMatch[1] : null;
    return videoId
      ? `https://player.vimeo.com/video/${videoId}?h=00a866fa73&&badge=0&autopause=0&player_id=0&app_id=58479`
      : url;
  }
  return url;
};

const MediaContent = ({ type, src }) => {
  const embedSrc = convertToEmbedUrl(src);

  if (src === "") return <></>;

  if (type === "video") {
    return (
      <div
        style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={embedSrc}
          title="Video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  } else if (type === "image") {
    return (
      <img
        src={src}
        alt="Post media"
        style={{ width: "100%", height: "auto" }}
      />
    );
  } else {
    return null; // メディアタイプが指定されていない場合は何も表示しない
  }
};

export default MediaContent;
