import React, { useState } from "react";
import { doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Tooltip } from "@mui/material";
// import LoaderName from "./LoaderName";
// import { PencilIcon } from "@heroicons/react/solid";
// import { useDocumentData } from "react-firebase-hooks/firestore";
// import ModalForm from "./ModalForm";

const ProfileName = () => {
//   const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");

  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  return (
    <>
      <div className="flex  mb-3 font-mono  sm:text-left  justify-center">
        {/* <ModalForm form={form} setOpen={setOpen} open={open} /> */}
        <div className="flex space-x-2">
          {/* <div className=" font-bold text-3xl">{user?.lastName}</div>
          <div className="font-bold text-3xl">{user?.firstName}</div> */}
        </div>
      </div>
    </>
  );
};

export default ProfileName;
