import React, { useState, useEffect } from "react";
import { Avatar, Divider, TextField } from "@mui/material";
import ReactionButton from "./ReactionButton";
import MediaContent from "./MediaContent";
import ImageRenderer from "./ImageRenderer";
import { doc, updateDoc, getDoc } from "@firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/base";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PostContent from "./PostContent";

import image from "../data/MMM.png";
import smallImage from "../data/MMM2.png"; // 添付ファイルの画像をインポート

const Post = ({ post }) => {
  const [editing, setEditing] = useState(false);
  const [newContent, setNewContent] = useState(post.content);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    const postRef = doc(db, "posts", post.id);
    await updateDoc(postRef, {
      content: newContent,
    });
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setNewContent(post.content); // 編集をキャンセルし、元の内容に戻す
  };

  const handleDelete = async () => {
    // isVisibleをfalseに更新することで「削除」する
    const postRef = doc(db, "posts", post.id);
    await updateDoc(postRef, {
      isVisible: false,
    });
    handleCloseDeleteDialog();
  };

  const [user, setUser] = useState(null);
  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid; // 現在のユーザーIDを取得
        const userDocRef = doc(db, "users", uid); // usersコレクションからユーザードキュメントへの参照を取得
        const docSnap = await getDoc(userDocRef); // ドキュメントのスナップショットを取得

        if (docSnap.exists()) {
          setUser(docSnap.data()); // スナップショットからユーザーデータを取得してステートに格納
        } else {
          console.log("No such document!"); // ドキュメントが存在しない場合
        }
      }
    };

    fetchUserData();
  }, []);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  // フラットなimages配列を生成
  let flattenedImages = [];
  if (Array.isArray(post.media.src)) {
    flattenedImages = post.media.src.flatMap(urlArray =>
      Array.isArray(urlArray.url) ? urlArray.url.map(url => ({ url })) : [{ url: urlArray }]
    );
  } else {
    flattenedImages = [{ url: post.media.src }];
  }

  return (
    <div className="border rounded-md p-2 mb-7 shadow-xl">
      {/* 投稿者 */}
      <div className="flex h-16 items-center">
        <Avatar
          src={image}
          alt={post.author}
          sx={{
            width: 56,
            height: 56,
            border: "3px solid #FFD700", // ゴールドボーダー
            boxShadow: "0px 0px 10px 3px rgba(255, 215, 0, 0.7)", // ゴールドシャドウ
            transition: "transform 0.3s", // ホバーアニメーション
            "&:hover": {
              transform: "scale(1.2)",
            },
          }}
        />
        <div className="ml-2 flex flex-col">
          <div>{post.author}</div>
          <small>
            {post.timestamp
              ? post.timestamp.toDate().toLocaleString()
              : "Loading..."}
          </small>
        </div>
      </div>
      <Divider />

      {/* 投稿テキスト */}
      {editing ? (
        <TextField
          fullWidth
          multiline
          rows={4}
          value={newContent}
          onChange={(e) => setNewContent(e.target.value)}
        />
      ) : (
        <div className="py-2">
          <PostContent content={post.content} />
        </div>
      )}

      {user?.isAdmin &&
        (editing ? (
          <div>
            <IconButton onClick={handleSave} aria-label="save">
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancel} aria-label="cancel">
              <CancelIcon />
            </IconButton>
          </div>
        ) : (
          <div>
            <IconButton onClick={handleEdit} aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleClickOpenDeleteDialog}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>

            <Dialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"削除確認"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  このポストを削除してもよろしいですか？この操作は元に戻せません。
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  キャンセル
                </Button>
                <Button onClick={handleDelete} color="primary" autoFocus>
                  削除
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ))}

      {/* メディア */}
      {post.media.type === "image" && <ImageRenderer images={flattenedImages} />}
      {post.media.type === "video" && <MediaContent type={post.media.type} src={post.media.src} />}
      <Divider />

      {/* リアクションボタン */}
      {/* <div className="flex mt-2">
        <ReactionButton type="like" />
        <ReactionButton type="comment" />
      </div> */}
    </div>
  );
};

export default Post;
