import React from "react";
import ContentLoader from "react-content-loader";

const PicLoaderW40 = (props) => {
  return (
    <>
  <ContentLoader 
    speed={0.5}
    width={160}
    height={160}
    viewBox="0 0 160 160"
    className=" w-40 h-40 sm:w-60 sm:h-60 rounded-full  border-4 items-center absolute m-auto left-0 right-0"
    backgroundColor="#d9e4d8"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="80" cy="80" r="84" />
  </ContentLoader>

    </>
  );
};

export default PicLoaderW40;
