import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import GroupIcon from "@mui/icons-material/Group";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SettingsIcon from "@mui/icons-material/Settings";
import ChatIcon from "@mui/icons-material/Chat";
import EventIcon from "@mui/icons-material/Event";
import GroupMembers from "./GroupMembers";
import DDPSetting from "./DDPSetting";
import GroupChat from "./GroupChat";
import Settings from "./SettingsGroup";
import Schedule from "./Schedule"; // スケジュールコンポーネントをインポート
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Tooltip,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import SurveyIcon from "@mui/icons-material/QuestionAnswer"; // アンケートアイコン
import CloseIcon from "@mui/icons-material/Close"; // ×アイコン
import MovieIcon from "@mui/icons-material/Movie"; // ムービーアイコンをインポート

import SophieMission from "./SophieMission";
import GroupChatEx from "./GroupChatEx";
import Webinar from "./Webinar";

const Group = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);
  const [user, setUser] = useState(null);
  const [isMissionDrawerOpen, setMissionDrawerOpen] = useState(false);
  const [isSurveyDialogOpen, setSurveyDialogOpen] = useState(false); // アンケートダイアログの状態
  const [isFabVisible, setFabVisible] = useState(true); // Fabの表示状態
  const [surveyAnswers, setSurveyAnswers] = useState({
    faceToFace: "",
    externalChat: "",
    inPerson: "",
    topic: "",
  }); // アンケートの回答
  const [isThankYouDialogOpen, setThankYouDialogOpen] = useState(false); // ありがとうダイアログの状態

  const surveyId = "202409Middle"; // ここでアンケートIDを設定

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const topOffset = isSmallScreen ? "56px" : "64px"; // スマホとそれ以上のサイズでtopのオフセットを変更

  useEffect(() => {
    const checkSurveyResponse = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser(userData);

          // すでにアンケートに回答しているか確認
          const userSurveyQuery = query(
            collection(db, "users", userId, "fab"),
            where("surveyId", "==", surveyId)
          );
          const userSurveySnap = await getDocs(userSurveyQuery);
          setFabVisible(userSurveySnap.empty); // 回答済みならアンケートアイコンを非表示
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Failed to fetch user", error);
      }
    };

    checkSurveyResponse();
  }, [surveyId]); // アンケートIDが変わるたびに実行

  useEffect(() => {
    const fetchGroup = async () => {
      if (!user) return;

      setLoading(true);
      try {
        if (!user.isAdmin && user.participatingGroup !== id) {
          navigate(`/group/${user.participatingGroup}`);
          return;
        }

        const docRef = doc(db, "groups", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setGroup(docSnap.data());
        } else {
          setError("Group not found");
        }
      } catch (err) {
        setError("Failed to fetch group");
      } finally {
        setLoading(false);
      }
    };

    fetchGroup();
  }, [id, user, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  useEffect(() => {
    // Fabが表示された時に2回ジャンプするアニメーション
    const fabElement = document.getElementById("survey-fab");
    if (fabElement) {
      fabElement.classList.add("jump-animation");
      setTimeout(() => {
        fabElement.classList.remove("jump-animation");
      }, 1200); // 2回ジャンプした後にクラスを削除
    }
  }, [isFabVisible]);

  const handleMissionClick = () => {
    setMissionDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setMissionDrawerOpen(false);
  };

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      // Chatを選択したとき
      setFabVisible(false); // Fabを非表示
    } else {
      if (surveyId && isFabVisible) {
        // Fabがすでに非表示なら再表示しない
        setFabVisible(true); // Fabを表示
      }
    }
  };

  const handleSurveyClick = async () => {
    if (!user) return;

    try {
      // ユーザーがすでにアンケートに回答しているかどうかを確認
      const userSurveyQuery = query(
        collection(db, "users", user.uid, "fab"),
        where("surveyId", "==", surveyId)
      );
      const userSurveySnap = await getDocs(userSurveyQuery);

      if (userSurveySnap.empty) {
        // 回答していない場合のみアンケートダイアログを開く
        setSurveyDialogOpen(true);
      } else {
        // すでに回答済みの場合はFabを非表示にする（任意）
        setFabVisible(false);
        console.log("ユーザーはすでにアンケートに回答済みです");
      }
    } catch (error) {
      console.error("アンケート回答のチェックに失敗しました", error);
    }
  };

  const handleSurveyClose = () => {
    setSurveyDialogOpen(false);
  };

  const handleSurveyAnswerChange = (event) => {
    const { name, value } = event.target;
    setSurveyAnswers((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleSaveSurvey = async () => {
  //   if (!surveyAnswers || !user) return;

  //   const surveyData = {
  //     createdAt: serverTimestamp(),
  //     surveyId,
  //     answers: surveyAnswers,
  //     userId: user.uid,
  //   };

  //   try {
  //     // アンケートデータをユーザの`fab`サブコレクションに保存
  //     const userFabRef = doc(db, "users", user.uid, "fab", surveyId);
  //     await setDoc(userFabRef, surveyData);

  //     console.log("アンケートが保存されました");

  //     setSurveyDialogOpen(false); // ダイアログを閉じる
  //     setThankYouDialogOpen(true); // ありがとうダイアログを開く
  //   } catch (error) {
  //     console.error("アンケートの保存に失敗しました", error);
  //   }
  // };

  const handleSaveSurvey = async () => {
    if (!surveyAnswers || !user || !group) return;

    const surveyData = {
      createdAt: serverTimestamp(),
      surveyId,
      answers: surveyAnswers,
      userId: user.uid,
      groupId: id, // グループIDを追加
    };

    try {
      // `/users/userId/fab` コレクションに保存
      const userFabRef = doc(db, "users", user.uid, "fab", surveyId);
      await setDoc(userFabRef, surveyData);

      // `/fab` コレクションにも保存
      const fabRef = collection(db, "fab");
      await addDoc(fabRef, surveyData);

      console.log(
        "アンケートが`/users/userId/fab`と`/fab`コレクションに保存されました"
      );

      setSurveyDialogOpen(false); // ダイアログを閉じる
      setThankYouDialogOpen(true); // ありがとうダイアログを開く
    } catch (error) {
      console.error("アンケートの保存に失敗しました", error);
    }
  };

  // const handleSaveSurvey = async () => {
  //   if (!surveyAnswers || !user) return;

  //   const surveyData = {
  //     createdAt: serverTimestamp(),
  //     surveyId,
  //     answers: surveyAnswers,
  //     userId: user.uid,
  //   };

  //   try {
  //     // アンケートコレクションに追加
  //     await addDoc(collection(db, "surveys"), surveyData);

  //     // ユーザコレクション内のサブコレクションに追加
  //     const userSurveyRef = doc(db, "users", user.uid, "surveys", surveyId);
  //     await setDoc(userSurveyRef, surveyData);

  //     console.log("アンケートが保存されました");

  //     setSurveyDialogOpen(false); // ダイアログを閉じる
  //     setThankYouDialogOpen(true); // ありがとうダイアログを開く
  //   } catch (error) {
  //     console.error("アンケートの保存に失敗しました", error);
  //   }
  // };

  const handleThankYouDialogClose = () => {
    setThankYouDialogOpen(false);
  };

  const handleCloseFab = () => {
    setFabVisible(false);
  };

  // console.log("value =>",value)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  

  return (
    <div
      style={{ display: "flex", flexDirection: "column", maxHeight: "100vh" }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleNavigationChange}
        style={{
          position: "fixed",
          top: topOffset,
          backgroundColor: "#ffffff",
          zIndex: 1000,
          maxWidth: "1152px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          height: "56px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Tooltip title="仲間" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 0 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="仲間"
            icon={<GroupIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="DDP" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 1 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="DDP"
            icon={<FavoriteIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="Chat" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 2 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="Chat"
            icon={<ChatIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>

        <Tooltip title="設定" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 3 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="設定"
            icon={<SettingsIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="ソフィーからのミッション" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 4 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            onClick={handleMissionClick}
            icon={<StarIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>

        {user?.isAdmin && (
            <Tooltip title="10/20" arrow>
              <BottomNavigationAction
                style={{
                  minWidth: "auto",
                  flex: "1",
                  color: value === 5 ? "#1976d2" : "#757575",
                  transition: "color 0.3s",
                }}
                label="10/20"
                icon={<MovieIcon style={{ fontSize: 30 }} />}
              />
            </Tooltip>
        )}

        {/* 管理者にのみ表示される設定ページ */}
        {/* {user?.isAdmin && (
          <Tooltip title="管理" arrow>
            <BottomNavigationAction
              style={{
                minWidth: "auto",
                flex: "1",
                color: value === 4 ? "#1976d2" : "#757575",
                transition: "color 0.3s",
              }}
              label="管理"
              icon={<ChatIcon style={{ fontSize: 30 }} />}
            />
          </Tooltip>
        )} */}
      </BottomNavigation>

      {/* Fab (Floating Action Button) for Survey */}
      <Fab
        id="survey-fab"
        color="primary"
        aria-label="アンケート"
        onClick={handleSurveyClick}
        style={{
          position: "fixed",
          bottom: "80px", // BottomNavigationの上に配置する
          right: "16px",
          zIndex: 1100,
          display: isFabVisible ? "block" : "none",
        }}
      >
        <SurveyIcon />
      </Fab>

      <div style={{ flex: 1, marginTop: topOffset }}>
        {value === 0 && <GroupMembers group={group} />}
        {value === 1 && <DDPSetting />}
        {value === 2 && <GroupChatEx groupId={id} />}
        {value === 3 && <Settings />}
        {value === 5 && <Webinar groupId={id} />}
      </div>

      <Drawer
        anchor="right"
        open={isMissionDrawerOpen}
        onClose={handleDrawerClose}
      >
        <SophieMission onClose={handleDrawerClose} />
      </Drawer>

      {/* アンケート */}
      <Dialog
        open={isSurveyDialogOpen}
        onClose={handleSurveyClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>アンケート</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            以下の質問にお答えください。
          </Typography>

          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <Typography variant="subtitle2">
                Zoomなどのツールで顔合わせをした。
              </Typography>
              <RadioGroup
                aria-label="face-to-face"
                name="faceToFace"
                value={surveyAnswers.faceToFace}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="はい"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="いいえ"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <Typography variant="subtitle2">
                外部のチャットツールを使用している。
              </Typography>
              <RadioGroup
                aria-label="external-chat"
                name="externalChat"
                value={surveyAnswers.externalChat}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="はい"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="いいえ"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <Typography variant="subtitle2">
                実際にあったことがある。
              </Typography>
              <RadioGroup
                aria-label="in-person"
                name="inPerson"
                value={surveyAnswers.inPerson}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="はい"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="いいえ"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* 新しい質問「実際の活動人数は？」 */}
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="activity-number-label">
                実際の活動人数は？
              </InputLabel>
              <Select
                labelId="activity-number-label"
                name="activityNumber"
                value={surveyAnswers.activityNumber || ""}
                onChange={handleSurveyAnswerChange}
                label="実際の活動人数は？"
              >
                {Array.from({ length: 10 }, (_, i) => (
                  <MenuItem key={i + 1} value={10 - i}>
                    {10 - i}人
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                label="盛り上がった話題は？"
                name="topic"
                value={surveyAnswers.topic}
                onChange={handleSurveyAnswerChange}
                multiline
                rows={4}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSurveyClose} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleSaveSurvey}
            color="primary"
            variant="contained"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={isSurveyDialogOpen}
        onClose={handleSurveyClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>アンケート</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            以下の質問にお答えください。
          </Typography>

          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <Typography variant="subtitle2">Zoomなどのツールで顔合わせをした。</Typography>
              <RadioGroup
                aria-label="face-to-face"
                name="faceToFace"
                value={surveyAnswers.faceToFace}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="はい" />
                <FormControlLabel value="no" control={<Radio />} label="いいえ" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <Typography variant="subtitle2">外部のチャットツールを使用している。</Typography>
              <RadioGroup
                aria-label="external-chat"
                name="externalChat"
                value={surveyAnswers.externalChat}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="はい" />
                <FormControlLabel value="no" control={<Radio />} label="いいえ" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <Typography variant="subtitle2">実際にあったことがある。</Typography>
              <RadioGroup
                aria-label="in-person"
                name="inPerson"
                value={surveyAnswers.inPerson}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="はい" />
                <FormControlLabel value="no" control={<Radio />} label="いいえ" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                label="盛り上がった話題は？"
                name="topic"
                value={surveyAnswers.topic}
                onChange={handleSurveyAnswerChange}
                multiline
                rows={4}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSurveyClose} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleSaveSurvey}
            color="primary"
            variant="contained"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={isThankYouDialogOpen}
        onClose={handleThankYouDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>ありがとうございます</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            アンケートにご協力いただき、ありがとうございます。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleThankYouDialogClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Group;
