import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ContactConfirmationPage() {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/home'); // ホームページに戻る
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        お問い合わせを受け付けました
      </Typography>
      <Typography variant="subtitle1">
        ご連絡いただきありがとうございます。運営側で内容を精査しご連絡させていただきます。
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackToHome}
        sx={{ mt: 3 }}
      >
        ホームに戻る
      </Button>
    </Container>
  );
}

export default ContactConfirmationPage;
