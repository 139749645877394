import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Schedule from "./Schedule";

const SophieMission = ({ onClose }) => {
  return (
    <div style={{ width: 300, padding: 16 }}>
      <IconButton onClick={onClose} style={{ float: "right" }}>
        <CloseIcon />
      </IconButton>
      <h2>ソフィーからのミッション</h2>
      <Schedule />
    </div>
  );
};

export default SophieMission;
