import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import japanMap from "../json/japan.topo.json"; // 日本のTopoJSONデータ
import { db } from "../api/firebase";
import { collection, query, where, getDocs, setDoc, doc, orderBy, limit } from "firebase/firestore";
import { Typography, CircularProgress, Box, Paper, Button } from "@mui/material";

// 英語名と日本語名のマッピング
const prefectureMapping = {
  "Hokkaido": "北海道",
  "Aomori": "青森県",
  "Iwate": "岩手県",
  "Miyagi": "宮城県",
  "Akita": "秋田県",
  "Yamagata": "山形県",
  "Fukushima": "福島県",
  "Ibaraki": "茨城県",
  "Tochigi": "栃木県",
  "Gunma": "群馬県",
  "Saitama": "埼玉県",
  "Chiba": "千葉県",
  "Tokyo": "東京都",
  "Kanagawa": "神奈川県",
  "Niigata": "新潟県",
  "Toyama": "富山県",
  "Ishikawa": "石川県",
  "Fukui": "福井県",
  "Yamanashi": "山梨県",
  "Nagano": "長野県",
  "Gifu": "岐阜県",
  "Shizuoka": "静岡県",
  "Aichi": "愛知県",
  "Mie": "三重県",
  "Shiga": "滋賀県",
  "Kyoto": "京都府",
  "Osaka": "大阪府",
  "Hyōgo": "兵庫県",
  "Nara": "奈良県",
  "Wakayama": "和歌山県",
  "Tottori": "鳥取県",
  "Shimane": "島根県",
  "Okayama": "岡山県",
  "Hiroshima": "広島県",
  "Yamaguchi": "山口県",
  "Tokushima": "徳島県",
  "Kagawa": "香川県",
  "Ehime": "愛媛県",
  "Kochi": "高知県",
  "Fukuoka": "福岡県",
  "Saga": "佐賀県",
  "Nagasaki": "長崎県",
  "Kumamoto": "熊本県",
  "Oita": "大分県",
  "Miyazaki": "宮崎県",
  "Kagoshima": "鹿児島県",
  "Okinawa": "沖縄県"
};

const fetchUsersByPrefecture = async () => {
  const userCollection = collection(db, "users");
  const q = query(
    userCollection,
    where("purpose", "==", "音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"),
    where("isVerified", "==", true),
    where("isStop", "==", false)
  );
  const userSnapshot = await getDocs(q);
  const userData = userSnapshot.docs.map((doc) => doc.data());

  // `participatingGroup`が存在しない、または空っぽの人をフィルタリング
  const filteredUserData = userData.filter(user => !user.participatingGroup || user.participatingGroup.length === 0);

  const prefectureCounts = {};
  filteredUserData.forEach((user) => {
    const prefecture = user.prefecture;
    if (prefectureCounts[prefecture]) {
      prefectureCounts[prefecture] += 1;
    } else {
      prefectureCounts[prefecture] = 1;
    }
  });

  return prefectureCounts;
};


// const fetchUsersByPrefecture = async () => {
//   const userCollection = collection(db, "users");
//   const q = query(
//     userCollection,
//     where("purpose", "==", "音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"),
//     where("isVerified", "==", true),
//     where("isStop", "==", false)
//   );
//   const userSnapshot = await getDocs(q);
//   const userData = userSnapshot.docs.map((doc) => doc.data());

//   const prefectureCounts = {};
//   userData.forEach((user) => {
//     const prefecture = user.prefecture;
//     if (prefectureCounts[prefecture]) {
//       prefectureCounts[prefecture] += 1;
//     } else {
//       prefectureCounts[prefecture] = 1;
//     }
//   });

//   return prefectureCounts;
// };

const getColorForCount = (count) => {
  if (count >= 7) return "#FF0000"; // 赤
  if (count >= 5) return "#FF7F00"; // オレンジ
  if (count >= 3) return "#FFFF00"; // 黄色
  if (count >= 2) return "#7FFFF0"; // ライム
  if (count >= 1) return "#00FF00"; // 緑
  return "#D6D6DA"; // グレー
};

const fetchStatistics = async () => {
  const statisticsCollection = collection(db, "statistics");
  const q = query(statisticsCollection, orderBy("date", "desc"), limit(1));
  const statisticsSnapshot = await getDocs(q);

  if (!statisticsSnapshot.empty) {
    return statisticsSnapshot.docs[0].data().data;
  }

  return null;
};

const updateStatistics = async (data) => {
  const today = new Date().toISOString().split("T")[0];
  await setDoc(doc(db, "statistics", today), {
    date: today,
    data: data,
  });
};

const MapComponent = () => {
  const [prefectureCounts, setPrefectureCounts] = useState({});
  const [selectedPrefecture, setSelectedPrefecture] = useState("");
  const [prefectureCount, setPrefectureCount] = useState(0);
  const [prefectureLoading, setPrefectureLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    const stats = await fetchStatistics();
    if (stats) {
      setPrefectureCounts(stats);
    } else {
      const counts = await fetchUsersByPrefecture();
      setPrefectureCounts(counts);
      await updateStatistics(counts);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleGeographyClick = (geo) => {
    const prefectureInJapanese = prefectureMapping[geo.properties.NAME_1] || geo.properties.NAME_1;
    setSelectedPrefecture(prefectureInJapanese);
    setPrefectureCount(prefectureCounts[prefectureInJapanese] || 0);
  };

  const handleUpdateStatistics = async () => {
    setLoading(true);
    const counts = await fetchUsersByPrefecture();
    setPrefectureCounts(counts);
    await updateStatistics(counts);
    setLoading(false);
  };

  return (
    <div className="h-fit border rounded-md shadow-lg" style={{ position: "relative" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateStatistics}
        sx={{ position: "absolute", left: "20px", top: "20px", zIndex: 1 }}
      >
        情報取得
      </Button>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ComposableMap
            projection="geoMercator"
            projectionConfig={{ scale: 1200, center: [137.5, 36] }}
          >
            <Geographies geography={japanMap}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const { NAME_1, centroid } = geo.properties;
                  const prefectureInJapanese = prefectureMapping[NAME_1];
                  const count = prefectureCounts[prefectureInJapanese] || 0;
                  return (
                    <g key={geo.rsmKey}>
                      <Geography
                        geography={geo}
                        onClick={() => handleGeographyClick(geo)}
                        style={{
                          default: {
                            fill: getColorForCount(count),
                            outline: "none",
                            stroke: "#000", // 県境の線をくっきりと表示
                            strokeWidth: 0.5,
                          },
                          hover: {
                            fill: "#F53",
                            outline: "none",
                            stroke: "#000", // 県境の線をくっきりと表示
                            strokeWidth: 0.5,
                          },
                          pressed: {
                            fill: "#E42",
                            outline: "none",
                            stroke: "#000", // 県境の線をくっきりと表示
                            strokeWidth: 0.5,
                          },
                        }}
                      />
                      {centroid && (
                        <text
                          x={centroid[0]}
                          y={centroid[1]}
                          textAnchor="middle"
                          style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize: "10px" }}
                        >
                          {NAME_1}
                        </text>
                      )}
                    </g>
                  );
                })
              }
            </Geographies>
          </ComposableMap>
          <Box
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
              width: "250px",
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              padding: "10px"
            }}
          >
            <Typography variant="h6">
              {selectedPrefecture}
            </Typography>
            {prefectureLoading ? (
              <CircularProgress />
            ) : (
              <Typography variant="body1">
                {prefectureCount} 人
              </Typography>
            )}
          </Box>
          <Paper
            sx={{
              position: "absolute",
              right: "20px",
              bottom: "20px",
              width: "200px",
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              padding: "10px"
            }}
          >
            <Typography variant="h6">
              凡例
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FF0000", mr: 1 }} />
              <Typography variant="body2">
                > 7 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FF7F00", mr: 1 }} />
              <Typography variant="body2">
                > 5 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FFFF00", mr: 1 }} />
              <Typography variant="body2">
                > 3 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#7FFFF0", mr: 1 }} />
              <Typography variant="body2">
                > 2 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#00FF00", mr: 1 }} />
              <Typography variant="body2">
                > 1 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#D6D6DA", mr: 1 }} />
              <Typography variant="body2">
                0 人
              </Typography>
            </Box>
          </Paper>
        </>
      )}
    </div>
  );
};

export default MapComponent;
