import React, { useEffect, useState } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../api/firebase";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@mui/material";

const GroupMemberCustom = () => {
  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unsubscribe;

    if (isSearching) {
      setLoading(true); // 検索開始時にローディングを表示

      const groupsCol = collection(db, "groups");
      const q = query(
        groupsCol,
        where("name", ">=", searchTerm),
        where("name", "<", searchTerm + "\uf8ff")
      );

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const groupList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log("Fetched groups:", groupList); // デバッグ用のログ
          setGroups(groupList);
          setLoading(false); // データ取得後にローディングを非表示
        },
        (error) => {
          console.error("Error fetching groups:", error);
          setLoading(false); // エラー時にもローディングを非表示
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe(); // クリーンアップ
      }
    };
  }, [isSearching, searchTerm]);

  const handleSearch = () => {
    setIsSearching(true);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        GroupMemberCustom
      </Typography>
      <TextField
        label="グループ名を検索"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleSearch}>
        検索
      </Button>
      <List>
        {groups.map((group) => (
          <ListItem key={group.id}>
            <ListItemText primary={group.name} />
          </ListItem>
        ))}
      </List>
      <Backdrop open={loading} style={{ zIndex: 1 }}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" style={{ marginLeft: 10 }}>
          検索中...
        </Typography>
      </Backdrop>
    </Container>
  );
};

export default GroupMemberCustom;

  