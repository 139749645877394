import React from "react";
import { Box, Typography, Link } from "@mui/material";

const TermsOfService = () => {
  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        利用規約
      </Typography>
      <Typography variant="body1" paragraph>
        本利用規約（以下、「本規約」といいます。）は、MasterMind（以下、「当社」といいます。）が提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
      </Typography>
      {/* 各セクションのタイトルと内容 */}
      <Typography variant="h6" gutterBottom>
        1. アカウント登録
      </Typography>
      <Typography variant="body1" paragraph>
        ユーザーはGoogleアカウントを使用してMasterMindにログインし、サービスを利用することができます。
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. アカウント登録
      </Typography>
      <Typography variant="body1" paragraph>
        ユーザーはGoogleアカウントを使用してMasterMindにログインし、サービスを利用することができます。
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. ユーザーの義務
      </Typography>
      <Typography variant="body1" paragraph>
        ユーザーは、日本の法律および国際法を遵守する義務があります。また、他人の権利を侵害する行為や、違法・不適切なコンテンツの投稿を禁じます。
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. サービスの変更と終了
      </Typography>
      <Typography variant="body1" paragraph>
        MasterMindは、サービスの内容を変更したり、提供を終了する権利を留保します。
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. 責任の制限
      </Typography>
      <Typography variant="body1" paragraph>
        MasterMindは、サービスの利用
        から生じる間接的な損害に対して責任を負いません。
      </Typography>

      {/* 利用規約の他のセクションを続けます... */}
      {/* 利用規約の最後に変更通知に関するセクションを追加 */}
      <Typography variant="h6" gutterBottom>
        6. 利用規約の変更
      </Typography>
      <Typography variant="body1" paragraph>
        MasterMindは、利用規約を随時変更する権利を有しています。変更はメールで通知され、ユーザーは最新の利用規約に従うことに同意します。
      </Typography>
    </Box>
  );
};

export default TermsOfService;
