import React, { useState, useEffect, useRef } from "react";
import { db } from "../api/firebase";
import { collection, query, onSnapshot, where, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import Post from "./Post";

const Feeds = () => {
  const [posts, setPosts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const loadMoreRef = useRef(null);

  useEffect(() => {
    const q = query(
      collection(db, "posts"),
      where("isVisible", "==", true),
      orderBy("timestamp", "desc"),
      limit(3)
    );

    const unsubscribePosts = onSnapshot(q, (querySnapshot) => {
      const postsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(postsArray);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

      if (querySnapshot.docs.length < 3) {
        setHasMore(false);
      }
    });

    return () => {
      unsubscribePosts();
    };
  }, []);

  const loadMorePosts = async () => {
    if (lastVisible && !loading && hasMore) {
      setLoading(true);
      const q = query(
        collection(db, "posts"),
        where("isVisible", "==", true),
        orderBy("timestamp", "desc"),
        startAfter(lastVisible),
        limit(3)
      );

      const querySnapshot = await getDocs(q);
      const newPosts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

      if (querySnapshot.docs.length < 3) {
        setHasMore(false);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMore) {
          loadMorePosts();
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.9 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [lastVisible, loading, hasMore]);

  return (
    <div>
      <div className="mb-5"></div>
      {posts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
      {loading && (
        <div className="text-center my-4">
          <p>読み込み中...</p>
        </div>
      )}
      {posts.length === 0 && !hasMore && (
        <div className="text-center my-4">
          <p>表示するフィードがありません。</p>
        </div>
      )}
      {hasMore && !loading && (
        <div ref={loadMoreRef} className="text-center my-4">
          <button onClick={loadMorePosts} disabled={loading}>
            もっと読み込む
          </button>
        </div>
      )}
    </div>
  );
};

export default Feeds;
