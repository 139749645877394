import React, { useEffect, useState } from 'react';
import { useStreamChatClient } from '../context/StreamChatProvider';

const ChannelLoaderEx = () => {
  const chatClient = useStreamChatClient();
  const [loading, setLoading] = useState(false);

  const excludedIDs = [
    "wpo4xvlL12QYGeHfmkGHCjrh9aW2",
    "QZvw3xeyFGbaUUL5etl8R8Sq59j1",
    "sLMZFtZx5RbPj2CPcW90fwex2gu1",
    "HqCUCJv5yHV7XcbbhJuwbRJYm1V2",
    "Gxf2Oq0WnhehnoMIPz1H9k5NURk1"
  ];

  const fetchAllChannels = async () => {
    if (!chatClient || loading) return;

    setLoading(true);

    try {
      const filters = { members: { $in: [chatClient.userID] } };
      let allChannels = [];
      let offset = 0;
      const channelLimit = 30;
      let hasMoreChannels = true;
      let channelNo = 1;

      while (hasMoreChannels) {
        const channels = await chatClient.queryChannels(filters, {}, { limit: channelLimit, offset });
        if (channels.length === 0) {
          hasMoreChannels = false;
          break;
        }

        allChannels = allChannels.concat(channels);
        offset += channels.length;
      }

      console.log(`取得したチャンネルの総数: ${allChannels.length}`);

      for (const channel of allChannels) {
        let allMessages = [];
        let hasMoreMessages = true;
        let messageOffset = 0;
        const messageLimit = 50;

        // メッセージをすべて取得
        while (hasMoreMessages) {
          try {
            const response = await channel.query({
              messages: { limit: messageLimit, offset: messageOffset }
            });
            const messages = response.messages;
            if (messages.length === 0) {
              hasMoreMessages = false;
            } else {
              allMessages = allMessages.concat(messages);
              messageOffset += messageLimit;
            }
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } catch (error) {
            console.error("メッセージの取得中にエラーが発生しました:", error);
            await new Promise((resolve) => setTimeout(resolve, 10000));
          }
        }

        // すべてのメンバーを取得
        const members = channel.state.members;
        const activeMemberIDs = new Set(allMessages.map((msg) => msg.user.id));

        // 一度もメッセージを送信していないメンバーをフィルタリング
        const inactiveMembers = Object.keys(members).filter(
          (memberID) => !activeMemberIDs.has(memberID) && !excludedIDs.includes(memberID)
        );

        console.log(`チャット No.${channelNo}`);
        console.log(`チャンネルID: ${channel.id}`);
        console.log(`一度も投稿していないメンバー: ${inactiveMembers.join(", ")}`);
        channelNo++;
      }

      console.log(`全チャンネルの取得が完了しました。`);
    } catch (error) {
      console.error('チャンネルの取得中にエラーが発生しました:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllChannels();
  }, [chatClient]);

  return null;
};

export default ChannelLoaderEx;
