import React, { useEffect, useState } from 'react';
import { useStreamChatClient } from '../context/StreamChatProvider';

const ChannelLoader = () => {
  const chatClient = useStreamChatClient();
  const [loading, setLoading] = useState(false);

  const fetchAllChannels = async () => {
    if (!chatClient || loading) return;

    setLoading(true);

    try {
      const filters = { members: { $in: [chatClient.userID] } };
      let allChannels = [];
      let offset = 0;
      const channelLimit = 30; // チャンネル取得のリミット
      let hasMoreChannels = true;
      let channelNo = 1; // チャンネル番号の初期化

      while (hasMoreChannels) {
        const channels = await chatClient.queryChannels(filters, {}, { limit: channelLimit, offset });
        if (channels.length === 0) {
          hasMoreChannels = false;
          break;
        }

        allChannels = allChannels.concat(channels);
        offset += channels.length;
      }

      console.log(`取得したチャンネルの総数: ${allChannels.length}`);

      for (const channel of allChannels) {
        let allMessages = [];
        let hasMoreMessages = true;
        let messageOffset = 0;
        const messageLimit = 50; // メッセージ取得のリミットを減らす

        while (hasMoreMessages) {
          try {
            const response = await channel.query({
              messages: { limit: messageLimit, offset: messageOffset }
            });
            const messages = response.messages;
            if (messages.length === 0) {
              hasMoreMessages = false;
            } else {
              allMessages = allMessages.concat(messages);
              messageOffset += messageLimit;
            }
            await new Promise((resolve) => setTimeout(resolve, 1000)); // 2秒待機を追加
          } catch (error) {
            console.error("メッセージの取得中にエラーが発生しました:", error);
            await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待機してリトライ
          }
        }

        const messageCount = allMessages.length;
        const participantCount = new Set(allMessages.map((msg) => msg.user.id)).size;

        console.log(`チャット No.${channelNo}`);
        console.log(`チャンネルID: ${channel.id}`);
        console.log(`会話数: ${messageCount}`);
        console.log(`会話者数: ${participantCount}`);
        channelNo++; // チャンネル番号をインクリメント
      }

      console.log(`全チャンネルの取得が完了しました。`);
    } catch (error) {
      console.error('チャンネルの取得中にエラーが発生しました:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllChannels();
  }, [chatClient]);

  return null;
};

export default ChannelLoader;


{/* <Route path="/admin/groupSearch" element={<ChannelLoader />} /> */}
