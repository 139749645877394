import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PostContent from "./PostContent"; // PostContentをインポート

const extractVimeoId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:video\/)?(\d+)(?:\/\S*)?/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const VideoPage = () => {
  const [videos, setVideos] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);
  const iframeRefs = useRef([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkAdminStatus(user.uid);
      } else {
        setIsAdmin(false);
      }
    });
    fetchVideos();
    return () => unsubscribe();
  }, []);

  const fetchVideos = () => {
    const videoCollection = query(
      collection(db, "videos"),
      orderBy("order", "desc") // 最新の動画が一番上になるように並び替え
    );
    onSnapshot(videoCollection, (snapshot) => {
      const videoList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVideos(videoList);
    });
  };

  const checkAdminStatus = async (uid) => {
    const userDocRef = doc(db, "users", uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      setIsAdmin(userDoc.data().isAdmin);
    }
  };

  const handleOpenDialog = (video = null) => {
    if (video) {
      setCurrentVideo(video);
      setTitle(video.title);
      setUrl(video.url);
      setDescription(video.description);
    } else {
      setCurrentVideo(null);
      setTitle("");
      setUrl("");
      setDescription("");
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTitle("");
    setUrl("");
    setDescription("");
  };

  const handleSaveVideo = async () => {
    if (currentVideo) {
      const videoDocRef = doc(db, "videos", currentVideo.id);
      await updateDoc(videoDocRef, {
        title,
        url,
        description,
      });
    } else {
      const firstOrder = videos.length ? videos[0].order + 1 : 1; // 一番上に追加するためのorder値
      await addDoc(collection(db, "videos"), {
        title,
        url,
        description,
        order: firstOrder,
        createdAt: serverTimestamp(),
      });
    }
    handleCloseDialog();
  };

  const handleDeleteVideo = async () => {
    if (videoToDelete) {
      const videoDocRef = doc(db, "videos", videoToDelete.id);
      await deleteDoc(videoDocRef);
      setVideoToDelete(null);
      setDeleteDialogOpen(false);
    }
  };

  const handleOpenDeleteDialog = (video) => {
    setVideoToDelete(video);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setVideoToDelete(null);
    setDeleteDialogOpen(false);
  };

  const isFormValid = () => {
    return (
      title.trim() !== "" && url.trim() !== "" && description.trim() !== ""
    );
  };

  const extractYouTubeId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|[^\/\n\s]+\/\S*?[^\w\s-]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const cleanYouTubeUrl = (url) => {
    const videoId = extractYouTubeId(url);
    return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
  };

  const handleMoveUp = async (index) => {
    if (index > 0) {
      const videoAbove = videos[index - 1];
      const videoCurrent = videos[index];
      await updateDoc(doc(db, "videos", videoAbove.id), {
        order: videoCurrent.order,
      });
      await updateDoc(doc(db, "videos", videoCurrent.id), {
        order: videoAbove.order,
      });
    }
  };

  const handleMoveDown = async (index) => {
    if (index < videos.length - 1) {
      const videoBelow = videos[index + 1];
      const videoCurrent = videos[index];
      await updateDoc(doc(db, "videos", videoBelow.id), {
        order: videoCurrent.order,
      });
      await updateDoc(doc(db, "videos", videoCurrent.id), {
        order: videoBelow.order,
      });
    }
  };

  const handlePlay = (index) => {
    iframeRefs.current.forEach((iframe, idx) => {
      if (iframe && idx !== index) {
        const src = iframe.src;
        iframe.src = ""; // 一時的にsrcを空にしてリセット
        iframe.src = src; // 元に戻す
      }
    });
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        動画
      </Typography>
      {isAdmin && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          動画を追加
        </Button>
      )}
      <Box mt={2}>
        {videos.map((video, index) => (
          <Card
            key={video.id}
            sx={{ marginBottom: "16px", transition: "all 0.3s ease" }}
          >
            <CardContent>
              <Typography variant="h5">{video.title}</Typography>
              <PostContent content={video.description} /> {/* PostContentを使用 */}
              <Box
                mt={2}
                sx={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  borderRadius: "4px",
                }}
              >
                {video.url.includes("vimeo.com") ? (
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                    <iframe
                      src={`https://player.vimeo.com/video/${extractVimeoId(video.url)}?h=00a866fa73&badge=0&autopause=0&player_id=0&app_id=58479`}
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                      style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                      title={video.title}
                    ></iframe>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </div>
                ) : video.url.includes("youtube.com") || video.url.includes("youtu.be") ? (
                  <iframe
                    src={cleanYouTubeUrl(video.url)}
                    title={video.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                  ></iframe>
                ) : (
                  <iframe
                    ref={(el) => (iframeRefs.current[index] = el)}
                    src={`${video.url}`}
                    title={video.title}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                    onClick={() => handlePlay(index)}
                  ></iframe>
                )}
              </Box>
            </CardContent>
            {isAdmin && (
              <CardActions>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenDialog(video)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => handleOpenDeleteDialog(video)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  color="default"
                  onClick={() => handleMoveUp(index)}
                  disabled={index === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  color="default"
                  onClick={() => handleMoveDown(index)}
                  disabled={index === videos.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </CardActions>
            )}
          </Card>
        ))}
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>動画を{currentVideo ? "編集" : "追加"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="動画のタイトル"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="動画のURL"
            type="url"
            fullWidth
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <TextField
            margin="dense"
            label="動画の説明"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleSaveVideo}
            color="primary"
            disabled={!isFormValid()}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>動画の削除</DialogTitle>
        <DialogContent>
          <Typography>本当にこの動画を削除しますか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDeleteVideo} color="secondary">
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VideoPage;
