import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { db, auth } from "../api/firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import AddIcon from "@mui/icons-material/Add";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';

const AddPostDialog = () => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");
  const [mediaType, setMediaType] = useState("video");
  const [src, setSrc] = useState("");
  const [files, setFiles] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        const userDocRef = doc(db, "users", uid);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          setUser(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchUserData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).slice(0, 5 - files.length).map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleMoveImage = (index, direction) => {
    setFiles(prevFiles => {
      const newFiles = [...prevFiles];
      const targetIndex = index + direction;
      if (targetIndex < 0 || targetIndex >= newFiles.length) return newFiles;
      [newFiles[index], newFiles[targetIndex]] = [newFiles[targetIndex], newFiles[index]];
      return newFiles;
    });
  };

  const handleDeleteImage = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleAddPost = async () => {
    let mediaUrls = [];

    if (mediaType === "image" && files.length) {
      const storageRef = ref(getStorage());
      const uploadPromises = files.map(({ file }) => {
        const fileRef = ref(storageRef, `images/${Date.now()}_${file.name}`);
        return uploadBytes(fileRef, file).then((snapshot) =>
          getDownloadURL(snapshot.ref)
        );
      });
      mediaUrls = await Promise.all(uploadPromises);
    }

    const postData = {
      author: "MasterMind事務局",
      content: content,
      timestamp: serverTimestamp(),
      isVisible: true,
      media: {
        type: mediaType,
        src: mediaType === "video" ? src : mediaUrls,
      },
    };

    try {
      const docRef = await addDoc(collection(db, "posts"), postData);
      console.log("Document written with ID: ", docRef.id);
      handleClose();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      {user?.isAdmin && (
        <div className="my-2">
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Postを追加
          </Button>
        </div>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>新しいPostの登録</DialogTitle>
        <DialogContent>
          <DialogContentText>下記の情報を入力してください。</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="content"
            label="発信内容"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="media-type-label">メディアの種類</InputLabel>
            <Select
              labelId="media-type-label"
              id="media-type"
              value={mediaType}
              label="Media Type"
              onChange={(e) => {
                setMediaType(e.target.value);
                setSrc("");
                setFiles([]);
              }}
            >
              <MenuItem value="video">Video</MenuItem>
              <MenuItem value="image">Image</MenuItem>
            </Select>
          </FormControl>
          {mediaType === "video" ? (
            <TextField
              margin="dense"
              id="src"
              label="メディアURL"
              type="text"
              fullWidth
              variant="outlined"
              value={src}
              onChange={(e) => setSrc(e.target.value)}
            />
          ) : (
            <>
              <Button
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
                sx={{ mt: 2 }}
              >
                画像を追加
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileChange}
                  multiple
                />
              </Button>
              <List>
                {files.map((file, index) => (
                  <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <img
                        src={file.preview}
                        alt={`画像 ${index + 1}`}
                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                      />
                    </ListItemIcon>
                    <IconButton
                      onClick={() => handleMoveImage(index, -1)}
                      disabled={index === 0}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleMoveImage(index, 1)}
                      disabled={index === files.length - 1}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <ListItemText primary={`画像 ${index + 1}`} />
                    <IconButton onClick={() => handleDeleteImage(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleAddPost} color="primary">
            登録する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPostDialog;
