import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        プライバシーポリシー
      </Typography>
      <Typography variant="body1" paragraph>
        MasterMind（以下、「当社」といいます。）は、ユーザーのプライバシーを尊重し、ユーザーの個人情報の保護に努めます。本プライバシーポリシーは、当社が提供するサービスに関連して収集する情報とその使用について説明します。
      </Typography>

      {/* 個人情報の収集 */}
      <Typography variant="h6" gutterBottom>
        個人情報の収集
      </Typography>
      <Typography variant="body1" paragraph>
        当社は、サービスの提供に必要な範囲で、Googleアカウントから名前、メールアドレス、アバターイメージ、および位置情報を含むユーザー情報を収集します。
      </Typography>

      {/* 個人情報の使用 */}
      <Typography variant="h6" gutterBottom>
        個人情報の使用
      </Typography>
      <Typography variant="body1" paragraph>
        収集した個人情報は、サービスの提供、改善、および当社またはユーザーの法的利益を守る目的で使用されます。また、ユーザー間のコミュニケーションを促進するために利用されます。
      </Typography>

      {/* 個人情報の共有と開示 */}
      <Typography variant="h6" gutterBottom>
        個人情報の共有と開示
      </Typography>
      <Typography variant="body1" paragraph>
        当社は、サービス内でのユーザー間のコミュニケーションを促進するため、または法的義務を履行するために、必要な範囲内で情報を開示することがあります。
      </Typography>

      {/* ユーザーの権利と選択肢 */}
      <Typography variant="h6" gutterBottom>
        ユーザーの権利と選択肢
      </Typography>
      <Typography variant="body1" paragraph>
        ユーザーは、個人情報に関するアクセス、修正、削除の権利を有しています。ユーザーは自己の情報を管理し、設定を通じて情報の処理を制御することができます。
      </Typography>

      {/* プライバシーポリシーの変更 */}
      <Typography variant="h6" gutterBottom>
        プライバシーポリシーの変更
      </Typography>
      <Typography variant="body1" paragraph>
        当社は、プライバシーポリシーを随時更新する権利を保持しています。重要な変更が行われた場合は、ユーザーに通知を行い、継続してサービスを使用することで変更に同意したとみなされます。
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
