import React, { useState, useEffect } from "react";
import { db, auth } from "../api/firebase"; // Adjust the import path as necessary
import { doc, onSnapshot, updateDoc, serverTimestamp } from "firebase/firestore";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  ThemeProvider,
  createTheme,
  Card,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // プライマリカラーを定義
    },
    secondary: {
      main: '#dc004e', // セカンダリカラーを定義
    },
    error: {
      main: '#f44336', // エラーカラーを定義
    },
  },
});

const Webinar = () => {
  const [panelistUrl, setPanelistUrl] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [openedBy, setOpenedBy] = useState("");
  const [openedByID, setOpenedByID] = useState(""); // openedByIDを追加
  const [openedAt, setOpenedAt] = useState(null);
  const { id } = useParams();
  const user = auth.currentUser; // 現在のユーザーを取得

  // Firestoreのリアルタイムリスナーでドキュメントの変更を監視
  useEffect(() => {
    if (!id) return;

    const groupDocRef = doc(db, "groups", id);

    const unsubscribe = onSnapshot(groupDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setPanelistUrl(data.panelistUrl);
        setIsOpened(data.isOpened || false);
        setOpenedBy(data.openedBy || "");
        setOpenedByID(data.openedByID || "");
        setOpenedAt(data.openedAt?.toDate() || null);

        // 他のユーザーがURLを開いたらダイアログを閉じる
        if (data.isOpened && isDialogOpen) {
          setIsDialogOpen(false);
        }
      }
    });

    // クリーンアップ: コンポーネントがアンマウントされたときにリスナーを解除
    return () => unsubscribe();
  }, [id, isDialogOpen]);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleOpenUrl = async () => {
    if (user) {
      const groupDocRef = doc(db, "groups", id);

      try {
        // Firestoreにグループの情報を更新
        await updateDoc(groupDocRef, {
          isOpened: true,
          openedBy: user.displayName || user.email, // ユーザ名（displayNameがない場合はemail）
          openedByID: user.uid, // uidを保存
          openedAt: serverTimestamp(),
        });

        // URLを開く
        window.open(panelistUrl, "_blank");
        setIsDialogOpen(false);
        setIsChecked(false);

      } catch (error) {
        console.error("Error updating group document:", error);
      }
    }
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setIsChecked(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="p-4">
        {/* YouTube Live セクション */}
        <Card className="mb-4">
          <CardContent>
            <Typography variant="subtitle1" className="mb-2">
              YouTube LiveのURL
            </Typography>
            <Typography variant="body2" className="mb-4">
              下記URLでYouTube Liveに参加できます。<br />
              このYouTube Liveはマスターマインドメンバーズ全員が参加できます。
            </Typography>
            <Typography variant="body1" color="primary" className="mb-4">
              https://youtube.com/live_stream_url
            </Typography>
          </CardContent>
        </Card>

        {/* Zoom セクション */}
        <Card>
          <CardContent>
            <Typography variant="subtitle1" className="mb-2">
              ZoomミーティングのURL
            </Typography>
            {isOpened ? (
              <Typography variant="body2" color="error" className="mb-4">
                {openedBy}がZoomミーティングのURLを取得しました。<br />
                {openedAt && `取得日時: ${openedAt.toLocaleString()}`}
                {openedByID === user?.uid && (
                  <Typography variant="body1" color="primary" className="mt-2">
                    ZoomミーティングURL: dummyURL
                  </Typography>
                )}
              </Typography>
            ) : (
              <>
                <Typography variant="body2" color="error" className="mb-4">
                  下記URLはマスターマインドメンバーズの1グループにつき1つ配布されるものです。<br />
                  グループの誰かが開いてしまうと、二度とURLが発行されません。<br />
                  誰がパネリストとして参加するかをグループ内で決定後、パネリストの方のみ開いてください。
                </Typography>
                <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                  ZoomミーティングのパネリストURLを開く
                </Button>
              </>
            )}
          </CardContent>
        </Card>

        <Dialog open={isDialogOpen} onClose={handleCancel}>
          <DialogTitle>注意事項</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="error" gutterBottom>
              下記URLは、最初に開いた人以降開けません。誰がパネリストとして参加するかグループ内でお話し合いをよろしくお願いします。
            </Typography>
            <Typography variant="body2" gutterBottom>
              ・1グループにつきお一人のアカウントが使えます。
            </Typography>
            <Typography variant="body2" gutterBottom>
              ・仮にお二人以上のアカウントが入室されますと、強制退去される可能性があります。
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  name="confirm"
                  color="primary"
                />
              }
              label="上記内容を確認しました。"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="inherit">
              キャンセル
            </Button>
            <Button
              onClick={handleOpenUrl}
              color="primary"
              disabled={!isChecked}
            >
              開く
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default Webinar;
