import React, { memo } from "react";
import HeaderContainerMixIN from "./HeaderContainerMixIN";

const Entrance = ({ outlet }) => {
  return (
    <>
      <HeaderContainerMixIN outlet={outlet} />
    </>
  );
};

export default memo(Entrance);
