import React, { useEffect, useState } from "react";
import { collection, getDocs,getDoc,doc } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Select, MenuItem, FormControl, InputLabel, Avatar, Modal, Box, Snackbar, CircularProgress, Backdrop } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import UserCard from "./UserCard";
import { useNavigate } from "react-router-dom";


const ItemTypes = {
  USER: 'user',
};

const GroupingTest = () => {
  const [users, setUsers] = useState([]);
  const [groupedPrefectureCounts, setGroupedPrefectureCounts] = useState({});
  const [totalUsersPerPrefecture, setTotalUsersPerPrefecture] = useState({});
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const navigate = useNavigate(); // React RouterのuseNavigateフックを使用
  const [isAdmin, setIsAdmin] = useState(false); // 管理者フラグ

  useEffect(() => {
    const checkAdmin = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin);
        if (!userData.isAdmin) {
          navigate('/'); // 管理者でない場合はホームページにリダイレクト
        }
      } else {
        navigate('/'); // ユーザー情報が存在しない場合もホームページにリダイレクト
      }
    };

    checkAdmin();
  }, [navigate]);


  useEffect(() => {
    const init = async () => {
      const usersCol = collection(db, "dummyUsers");
      const usersSnapshot = await getDocs(usersCol);
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersList);
    };
    init();
  }, []);

  useEffect(() => {
    const countPrefectures = () => {
      const counts = users.reduce((accumulator, currentUser) => {
        const { prefecture } = currentUser;
        if (prefecture in accumulator) {
          accumulator[prefecture].push(currentUser);
        } else {
          accumulator[prefecture] = [currentUser];
        }
        return accumulator;
      }, {});

      const groupedCounts = {};
      const totalUsers = {};

      Object.entries(counts).forEach(([prefecture, users]) => {
        const numUsers = users.length;
        const minGroupSize = 8;
        const maxGroupSize = 8;

        let numGroups = Math.ceil(numUsers / maxGroupSize);
        let groupSize = Math.ceil(numUsers / numGroups);

        while (groupSize > maxGroupSize || (numGroups * groupSize - numUsers > numGroups - 1)) {
          numGroups++;
          groupSize = Math.ceil(numUsers / numGroups);
        }

        let groupedUsers = [];
        for (let i = 0; i < numGroups; i++) {
          const start = i * groupSize;
          let end = start + groupSize;
          if (end > numUsers) {
            end = numUsers;
          }
          groupedUsers.push(users.slice(start, end));
        }

        if (groupedUsers.length > 0 && groupedUsers[groupedUsers.length - 1].length < minGroupSize) {
          const lastGroup = groupedUsers.pop();
          while (lastGroup.length > 0) {
            groupedUsers[groupedUsers.length - 1].push(lastGroup.shift());
          }
        }

        groupedCounts[prefecture] = {
          numGroups: groupedUsers.length,
          groupSizes: groupedUsers.map(group => group.length),
          groupUsers: groupedUsers.map((group, groupIndex) =>
            group.map(user => ({ ...user, prefecture, groupIndex }))
          ),
        };

        totalUsers[prefecture] = numUsers;
      });

      setGroupedPrefectureCounts(groupedCounts);
      setTotalUsersPerPrefecture(totalUsers);
    };

    countPrefectures();
  }, [users]);

  const addNewGroup = (prefecture) => {
    setGroupedPrefectureCounts(prev => {
      const updated = { ...prev };
      updated[prefecture].groupUsers.push([]);
      updated[prefecture].groupSizes.push(0);
      return updated;
    });
  };

  const moveUser = (user, targetGroupIndex, targetPrefecture) => {
    setLoading(true);
    setTimeout(() => {
      setGroupedPrefectureCounts(prev => {
        const updated = { ...prev };
        const sourcePrefecture = user.prefecture;
        const sourceGroupIndex = user.groupIndex;
        const sourceGroup = updated[sourcePrefecture]?.groupUsers?.[sourceGroupIndex];
        const targetGroup = updated[targetPrefecture]?.groupUsers?.[targetGroupIndex];

        console.log("Moving user:", user);
        console.log("Source prefecture:", sourcePrefecture);
        console.log("Source group index:", sourceGroupIndex);
        console.log("Source group:", sourceGroup);
        console.log("Target group:", targetGroup);

        if (!sourceGroup) {
          console.error("Source group not found:", sourcePrefecture, sourceGroupIndex);
          setLoading(false);
          return prev;
        }

        if (!targetGroup) {
          console.error("Target group not found:", targetPrefecture, targetGroupIndex);
          setLoading(false);
          return prev;
        }

        const userIndex = sourceGroup.findIndex(u => u.uid === user.uid);
        if (userIndex > -1) {
          const [movedUser] = sourceGroup.splice(userIndex, 1);
          targetGroup.push(movedUser);
          movedUser.prefecture = targetPrefecture;
          movedUser.groupIndex = targetGroupIndex;
        }

        setLoading(false);
        setSnackbarOpen(true);

        return updated;
      });
    }, 1000); // Simulate a delay for the move process
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Typography variant="h4" gutterBottom>Grouping Test</Typography>
      {Object.entries(groupedPrefectureCounts).map(([prefecture, counts]) => (
        <Accordion key={prefecture}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{prefecture} - Total Users: {totalUsersPerPrefecture[prefecture]}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {counts.groupSizes.map((groupSize, index) => (
              <Group
                key={index}
                group={counts.groupUsers[index]}
                groupIndex={index}
                prefecture={prefecture}
                setGroupedPrefectureCounts={setGroupedPrefectureCounts}
                groupedPrefectureCounts={groupedPrefectureCounts}
                moveUser={moveUser}
              />
            ))}
            <div className="mt-4"></div>
            <Button variant="outlined" onClick={() => addNewGroup(prefecture)}>
              新しいグループを追加
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
        <Typography sx={{ ml: 2 }}>移動処理中</Typography>
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="完了しました！"
      />
    </DndProvider>
  );
};

const Group = ({ group, groupIndex, prefecture, setGroupedPrefectureCounts, groupedPrefectureCounts, moveUser }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.USER,
    drop: (item) => moveUser(item, groupIndex, prefecture),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Group {groupIndex + 1}: {group.length} people</Typography>
      </AccordionSummary>
      <AccordionDetails ref={drop} style={{ backgroundColor: isOver ? 'lightgreen' : 'white' }}>
        {group.map((user, userIndex) => (
          <DraggableUserCard
            key={userIndex}
            user={user}
            groupIndex={groupIndex}
            prefecture={prefecture}
            groupedPrefectureCounts={groupedPrefectureCounts}
            moveUser={moveUser}
            userIndex={userIndex}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const DraggableUserCard = ({ user, groupIndex, prefecture, groupedPrefectureCounts, moveUser, userIndex }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.USER,
    item: { ...user, groupIndex, prefecture },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleMoveClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleMoveUser = () => {
    const [targetPrefecture, targetGroupIndex] = selectedGroup.split(":");
    console.log("Selected group:", selectedGroup);
    console.log("Target prefecture:", targetPrefecture);
    console.log("Target group index:", targetGroupIndex);
    moveUser(user, parseInt(targetGroupIndex), targetPrefecture);
    setModalOpen(false);
  };

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Accordion sx={{ mb: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            minHeight: 56,
            height: 56,
            display: 'flex',
            alignItems: 'center',
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          <Typography sx={{ width: '2em', flexShrink: 0 }}>{userIndex + 1}.</Typography>
          <Avatar sx={{ bgcolor: "secondary.main", mr: 2 }}>
            {user.name.charAt(0)}
          </Avatar>
          <Typography>{user.name}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            UID: {user.uid}
          </Typography>
          <Button variant="outlined" onClick={handleMoveClick}>
            グループの移動
          </Button>
        </AccordionDetails>
      </Accordion>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="move-group-modal-title"
        aria-describedby="move-group-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="move-group-modal-title" variant="h6" component="h2">
            グループの移動
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="select-group-label">グループ選択</InputLabel>
            <Select
              labelId="select-group-label"
              id="select-group"
              value={selectedGroup}
              onChange={handleGroupChange}
            >
              {Object.entries(groupedPrefectureCounts).map(([pref, counts]) =>
                counts.groupUsers.map((group, index) => (
                  <MenuItem key={`${pref}:${index}`} value={`${pref}:${index}`}>
                    {pref} - Group {index + 1}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <Button variant="contained" onClick={handleMoveUser} sx={{ mt: 2 }}>
            移動
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default GroupingTest;
