import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  orderBy,
  startAt,
  endAt,
} from "firebase/firestore";
import { db } from "../api/firebase";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const prefectures = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [verifiedCount, setVerifiedCount] = useState(0);
  const [unverifiedCount, setUnverifiedCount] = useState(0);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const debounceTimeoutRef = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openPrefectureDialog, setOpenPrefectureDialog] = useState(false);
  const [selectedPrefecture, setSelectedPrefecture] = useState("");

  useEffect(() => {
    fetchCounts();
  }, []);

  useEffect(() => {
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery.toLowerCase());
    }, 500);

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      fetchUsers();
    } else {
      setUsers([]);
    }
  }, [debouncedSearchQuery, tabValue]);

  const fetchCounts = useCallback(async () => {
    try {
      const totalQuery = query(collection(db, "users"));
      const totalSnapshot = await getDocs(totalQuery);
      const totalUsers = totalSnapshot.docs;

      const verifiedUsers = totalUsers.filter(
        (doc) => doc.data().isVerified === true
      );
      const unverifiedUsers = totalUsers.filter(
        (doc) => doc.data().isVerified !== true
      );

      setVerifiedCount(verifiedUsers.length);
      setUnverifiedCount(unverifiedUsers.length);
      setTotalUsersCount(totalUsers.length);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  }, []);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const usersQuery = query(
        collection(db, "users"),
        where("isVerified", "==", tabValue === 0),
        orderBy("name"),
        startAt(debouncedSearchQuery),
        endAt(debouncedSearchQuery + "\uf8ff")
      );

      const querySnapshot = await getDocs(usersQuery);
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoading(false);
  }, [debouncedSearchQuery, tabValue]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleVerificationToggle = async (userId, isVerified) => {
    const userDocRef = doc(db, "users", userId);
    try {
      await updateDoc(userDocRef, {
        isVerified: !isVerified,
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, isVerified: !isVerified } : user
        )
      );
      fetchCounts();
    } catch (error) {
      console.error("Error updating user verification:", error);
    }
  };

  const handleStopToggle = async (userId, isStop) => {
    const userDocRef = doc(db, "users", userId);
    try {
      await updateDoc(userDocRef, {
        isStop: !isStop,
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, isStop: !isStop } : user
        )
      );
      fetchCounts();
    } catch (error) {
      console.error("Error updating user stop status:", error);
    }
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleDialogSave = async () => {
    const userDocRef = doc(db, "users", selectedUser.id);
    try {
      await updateDoc(userDocRef, {
        registrationEmail: selectedUser.registrationEmail,
        registrationFurigana: selectedUser.registrationFurigana,
        registrationName: selectedUser.registrationName,
        registrationPhone: selectedUser.registrationPhone,
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser.id ? { ...selectedUser } : user
        )
      );
      handleDialogClose();
    } catch (error) {
      console.error("Error updating user info:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handlePrefectureClick = (user) => {
    setSelectedUser(user);
    setSelectedPrefecture(user.prefecture || "");
    setOpenPrefectureDialog(true);
  };

  const handlePrefectureDialogClose = () => {
    setOpenPrefectureDialog(false);
    setSelectedUser(null);
  };

  const handlePrefectureSave = async () => {
    const userDocRef = doc(db, "users", selectedUser.id);
    try {
      await updateDoc(userDocRef, {
        prefecture: selectedPrefecture,
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser.id
            ? { ...selectedUser, prefecture: selectedPrefecture }
            : user
        )
      );
      handlePrefectureDialogClose();
    } catch (error) {
      console.error("Error updating user prefecture:", error);
    }
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        ユーザー一覧
      </Typography>
      <Typography variant="body1" gutterBottom>
        総ユーザー数: {totalUsersCount}
      </Typography>
      <TextField
        placeholder="検索"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {searchQuery === "" ? (
        <Typography variant="body1" align="center" mt={2}>
          ボックスに入力しユーザを検索してください
        </Typography>
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label={`認証済み (${verifiedCount}人)`} />
              <Tab label={`未認証 (${unverifiedCount}人)`} />
            </Tabs>
          </Box>
          {loading ? (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
              <Typography variant="body1" ml={2}>
                取得中...
              </Typography>
            </Box>
          ) : users.length === 0 ? (
            <Typography variant="body1" align="center" mt={2}>
              該当するユーザーはいません。
            </Typography>
          ) : (
            <List
              style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
            >
              {users.map((user) => (
                <Card
                  key={user.id}
                  sx={{
                    marginBottom: "16px",
                    border: "1px solid #ccc",
                    transition: "background-color 0.15s, border-color 0.15s",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                      borderColor: "#999",
                      cursor: "pointer",
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <Typography>認証</Typography>
                        <Switch
                          checked={user.isVerified === true}
                          onChange={() =>
                            handleVerificationToggle(user.id, user.isVerified)
                          }
                        />
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography>停止</Typography>
                        <Switch
                          checked={user.isStop === true}
                          onChange={() =>
                            handleStopToggle(user.id, user.isStop)
                          }
                        />
                      </Box>
                    </Box>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={user.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.name || "名前未設定"}
                        secondary={user.email || "メールアドレス未設定"}
                      />
                    </ListItem>
                    <Typography variant="body2">
                      <strong>目的:</strong> {user.purpose || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録日時:</strong>{" "}
                      {user.createdAt?.toDate().toLocaleString() || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>UID:</strong> {user.id}
                    </Typography>
                    <Typography variant="body2">
                      <strong>居住県:</strong> {user.prefecture || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録名:</strong>{" "}
                      {user.registrationName || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録電話番号:</strong>{" "}
                      {user.registrationPhone || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録ふりがな:</strong>{" "}
                      {user.registrationFurigana || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録メールアドレス:</strong>{" "}
                      {user.registrationEmail || "未設定"}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => handleEditClick(user)}
                    >
                      申し込みデータを修正する
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handlePrefectureClick(user)}
                      //   sx={{ mt: 1 }}
                    >
                      居住県を修正する
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </List>
          )}
        </>
      )}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>ユーザー情報を編集</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="登録メールアドレス"
            type="email"
            fullWidth
            name="registrationEmail"
            value={selectedUser?.registrationEmail || ""}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="登録ふりがな"
            type="text"
            fullWidth
            name="registrationFurigana"
            value={selectedUser?.registrationFurigana || ""}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="登録名"
            type="text"
            fullWidth
            name="registrationName"
            value={selectedUser?.registrationName || ""}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="登録電話番号"
            type="text"
            fullWidth
            name="registrationPhone"
            value={selectedUser?.registrationPhone || ""}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPrefectureDialog} onClose={handlePrefectureDialogClose}>
        <DialogTitle>居住県を修正する</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={selectedPrefecture}
            onChange={(e) => setSelectedPrefecture(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              都道府県を選択
            </MenuItem>
            {prefectures.map((pref) => (
              <MenuItem key={pref} value={pref}>
                {pref}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrefectureDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handlePrefectureSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersList;
