import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  collection,
  getDocs,
  query,
  doc,
  updateDoc,
  orderBy,
  startAt,
  endAt,
} from "firebase/firestore";
import { db } from "../api/firebase";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  ListItemText,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom'; // react-router-domのuseNavigateをインポート
import PurposeSetting from "./PurposeSetting"; // Import PurposeSetting component
import HokkaidoMunicipalitySelect from "./HokkaidoMunicipalitySelect"; // Import HokkaidoMunicipalitySelect component

const prefectures = [
  "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県",
  "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県",
  "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県",
  "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県",
  "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県",
  "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県",
];

const UserListEx = () => {
  const [users, setUsers] = useState([]);
  const [unverifiedUsers, setUnverifiedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [hideVerified, setHideVerified] = useState(false);
  const debounceTimeoutRef = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openPrefectureDialog, setOpenPrefectureDialog] = useState(false);
  const [selectedPrefecture, setSelectedPrefecture] = useState("");
  const [openMunicipalityDialog, setOpenMunicipalityDialog] = useState(false);
  const [selectedMunicipality, setSelectedMunicipality] = useState("");

  const navigate = useNavigate(); // useNavigateフックの初期化

  useEffect(() => {
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery.toLowerCase());
    }, 500);

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      fetchUsers();
    } else {
      setUsers([]);
      setUnverifiedUsers([]);
    }
  }, [debouncedSearchQuery]);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const usersQuery = query(
        collection(db, "users"),
        orderBy("name"),
        startAt(debouncedSearchQuery),
        endAt(debouncedSearchQuery + "\uf8ff")
      );

      const emailQuery = query(
        collection(db, "users"),
        orderBy("email"),
        startAt(debouncedSearchQuery),
        endAt(debouncedSearchQuery + "\uf8ff")
      );

      const [usersSnapshot, emailSnapshot] = await Promise.all([
        getDocs(usersQuery),
        getDocs(emailQuery),
      ]);

      const usersData = [
        ...usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
        ...emailSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
      ];

      const uniqueUsersData = Array.from(
        new Map(usersData.map((user) => [user.id, user])).values()
      );

      setUsers(uniqueUsersData);
      setUnverifiedUsers(uniqueUsersData.filter((user) => !user.isVerified));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoading(false);
  }, [debouncedSearchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleHideVerifiedChange = (e) => {
    setHideVerified(e.target.checked);
  };

  const handleVerificationToggle = async (userId, isVerified) => {
    const userDocRef = doc(db, "users", userId);
    try {
      await updateDoc(userDocRef, {
        isVerified: !isVerified,
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, isVerified: !isVerified } : user
        )
      );
      setUnverifiedUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== userId)
      );
    } catch (error) {
      console.error("Error updating user verification:", error);
    }
  };

  const handleStopToggle = async (userId, isStop) => {
    const userDocRef = doc(db, "users", userId);
    try {
      await updateDoc(userDocRef, {
        isStop: !isStop,
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, isStop: !isStop } : user
        )
      );
    } catch (error) {
      console.error("Error updating user stop status:", error);
    }
  };

  const handleDuplicateToggle = async (userId, isDuplicate) => {
    const userDocRef = doc(db, "users", userId);
    try {
      await updateDoc(userDocRef, {
        isDuplicate: !isDuplicate,
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, isDuplicate: !isDuplicate } : user
        )
      );
    } catch (error) {
      console.error("Error updating user duplicate status:", error);
    }
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleDialogSave = async () => {
    const userDocRef = doc(db, "users", selectedUser.id);
    try {
      await updateDoc(userDocRef, {
        registrationEmail: selectedUser.registrationEmail,
        registrationFurigana: selectedUser.registrationFurigana,
        registrationName: selectedUser.registrationName,
        registrationPhone: selectedUser.registrationPhone,
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser.id ? { ...selectedUser } : user
        )
      );
      handleDialogClose();
    } catch (error) {
      console.error("Error updating user info:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handlePrefectureClick = (user) => {
    setSelectedUser(user);
    setSelectedPrefecture(user.prefecture || "");
    setOpenPrefectureDialog(true);
  };

  const handlePrefectureDialogClose = () => {
    setOpenPrefectureDialog(false);
    setSelectedUser(null);
  };

  const handlePrefectureSave = async () => {
    const userDocRef = doc(db, "users", selectedUser.id);
    try {
      await updateDoc(userDocRef, {
        prefecture: selectedPrefecture,
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser.id
            ? { ...selectedUser, prefecture: selectedPrefecture }
            : user
        )
      );
      handlePrefectureDialogClose();
    } catch (error) {
      console.error("Error updating user prefecture:", error);
    }
  };

  const handleMunicipalityClick = (user) => {
    setSelectedUser(user);
    setSelectedMunicipality(user.municipality || "");
    setOpenMunicipalityDialog(true);
  };

  const handleMunicipalityDialogClose = () => {
    setOpenMunicipalityDialog(false);
    setSelectedUser(null);
  };

  const handleMunicipalitySave = async () => {
    const userDocRef = doc(db, "users", selectedUser.id);
    try {
      await updateDoc(userDocRef, {
        municipality: selectedMunicipality,
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser.id
            ? { ...selectedUser, municipality: selectedMunicipality }
            : user
        )
      );
      handleMunicipalityDialogClose();
    } catch (error) {
      console.error("Error updating user municipality:", error);
    }
  };

  const handleGroupClick = (groupId) => {
    window.open(`/group/${groupId}`, "_blank");
  };
  const displayedUsers = hideVerified ? unverifiedUsers : users;

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        ユーザー一覧
      </Typography>
      <TextField
        placeholder="検索"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box display="flex" alignItems="center" mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideVerified}
              onChange={handleHideVerifiedChange}
              color="primary"
            />
          }
          label="認証済みのユーザーを省く"
        />
        <Typography variant="body1" ml={2}>
          該当ユーザー数: {displayedUsers.length}
        </Typography>
      </Box>
      {searchQuery === "" ? (
        <Typography variant="body1" align="center" mt={2}>
          ボックスに入力しユーザを検索してください
        </Typography>
      ) : (
        <>
          {loading ? (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
              <Typography variant="body1" ml={2}>
                取得中...
              </Typography>
            </Box>
          ) : displayedUsers.length === 0 ? (
            <Typography variant="body1" align="center" mt={2}>
              該当するユーザーはいません。
            </Typography>
          ) : (
            <List
              style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
            >
              {displayedUsers.map((user) => (
                <Card
                  key={user.id}
                  sx={{
                    marginBottom: "16px",
                    border: "1px solid #ccc",
                    transition: "background-color 0.15s, border-color 0.15s",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                      borderColor: "#999",
                      cursor: "pointer",
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <Typography>認証</Typography>
                        <Switch
                          checked={user.isVerified === true}
                          onChange={() =>
                            handleVerificationToggle(user.id, user.isVerified)
                          }
                        />
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography>停止</Typography>
                        <Switch
                          checked={user.isStop === true}
                          onChange={() =>
                            handleStopToggle(user.id, user.isStop)
                          }
                        />
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography>重複登録</Typography>
                        <Switch
                          checked={user.isDuplicate === true}
                          onChange={() =>
                            handleDuplicateToggle(user.id, user.isDuplicate)
                          }
                        />
                      </Box>
                    </Box>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={user.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.name || "名前未設定"}
                        secondary={user.email || "メールアドレス未設定"}
                      />
                    </ListItem>
                    <Typography variant="body2">
                      <strong>目的:</strong> {user.purpose || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録日時:</strong>{" "}
                      {user.createdAt?.toDate().toLocaleString() || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>UID:</strong> {user.id}
                    </Typography>
                    <Typography variant="body2">
                      <strong>居住県:</strong> {user.prefecture || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>市町村:</strong> {user.municipality || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録名:</strong>{" "}
                      {user.registrationName || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録電話番号:</strong>{" "}
                      {user.registrationPhone || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録ふりがな:</strong>{" "}
                      {user.registrationFurigana || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>登録メールアドレス:</strong>{" "}
                      {user.registrationEmail || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>参加グループID:</strong>{" "}
                      {user.participatingGroup ? (
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => handleGroupClick(user.participatingGroup)}
                        >
                          {user.participatingGroup}
                        </Link>
                      ) : (
                        "なし"
                      )}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => handleEditClick(user)}
                    >
                      申し込みデータを修正する
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handlePrefectureClick(user)}
                    >
                      居住県を修正する
                    </Button>
                    {user.prefecture === "北海道" && (
                      <Button
                        variant="outlined"
                        onClick={() => handleMunicipalityClick(user)}
                      >
                        市町村を修正する
                      </Button>
                    )}
                    <PurposeSetting userId={user.id} /> {/* Add PurposeSetting component */}
                  </CardContent>
                </Card>
              ))}
            </List>
          )}
        </>
      )}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>ユーザー情報を編集</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="登録メールアドレス"
            type="email"
            fullWidth
            name="registrationEmail"
            value={selectedUser?.registrationEmail || ""}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="登録ふりがな"
            type="text"
            fullWidth
            name="registrationFurigana"
            value={selectedUser?.registrationFurigana || ""}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="登録名"
            type="text"
            fullWidth
            name="registrationName"
            value={selectedUser?.registrationName || ""}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="登録電話番号"
            type="text"
            fullWidth
            name="registrationPhone"
            value={selectedUser?.registrationPhone || ""}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPrefectureDialog} onClose={handlePrefectureDialogClose}>
        <DialogTitle>居住県を修正する</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={selectedPrefecture}
            onChange={(e) => setSelectedPrefecture(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              都道府県を選択
            </MenuItem>
            {prefectures.map((pref) => (
              <MenuItem key={pref} value={pref}>
                {pref}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrefectureDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handlePrefectureSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMunicipalityDialog}
        onClose={handleMunicipalityDialogClose}
      >
        <DialogTitle>市町村を修正する</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={selectedMunicipality}
            onChange={(e) => setSelectedMunicipality(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              市町村を選択
            </MenuItem>
            {municipalities.map((municipality) => (
              <MenuItem key={municipality} value={municipality}>
                {municipality}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMunicipalityDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleMunicipalitySave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserListEx;

const municipalities = [
  "札幌市", "江別市", "千歳市", "恵庭市", "北広島市", "石狩市", "当別町", "新篠津村", "岩見沢市", "夕張市",
  "美唄市", "芦別市", "赤平市", "三笠市", "滝川市", "砂川市", "歌志内市", "深川市", "南幌町", "奈井江町",
  "上砂川町", "由仁町", "長沼町", "栗山町", "月形町", "浦臼町", "新十津川町", "妹背牛町", "秩父別町", "雨竜町",
  "北竜町", "沼田町", "小樽市", "倶知安町", "寿都町", "黒松内町", "蘭越町", "ニセコ町", "喜茂別町", "京極町",
  "共和町", "岩内町", "積丹町", "古平町", "仁木町", "余市町", "島牧村", "真狩村", "留寿都村", "泊村",
  "神恵内村", "赤井川村", "室蘭市", "苫小牧市", "登別市", "伊達市", "豊浦町", "洞爺湖町", "壮瞥町", "白老町",
  "厚真町", "安平町", "むかわ町", "浦河町", "日高町", "平取町", "新冠町", "様似町", "えりも町", "新ひだか町",
  "函館市", "北斗市", "松前町", "福島町", "知内町", "木古内町", "七飯町", "鹿部町", "森町", "八雲町",
  "長万部町", "江差町", "上ノ国町", "厚沢部町", "乙部町", "奥尻町", "今金町", "せたな町", "旭川市", "富良野市",
  "士別市", "名寄市", "鷹栖町", "東神楽町", "当麻町", "比布町", "愛別町", "上川町", "東川町", "美瑛町",
  "上富良野町", "中富良野町", "南富良野町", "幌加内町", "和寒町", "剣淵町", "下川町", "美深町", "中川町", "占冠村",
  "音威子府村", "留萌市", "増毛町", "小平町", "苫前町", "羽幌町", "遠別町", "天塩町", "初山別村", "稚内市",
  "浜頓別町", "中頓別町", "枝幸町", "豊富町", "幌延町", "礼文町", "利尻町", "利尻富士町", "猿払村", "網走市",
  "北見市", "紋別市", "美幌町", "津別町", "大空町", "斜里町", "清里町", "小清水町", "訓子府町", "置戸町",
  "佐呂間町", "遠軽町", "湧別町", "滝上町", "興部町", "雄武町", "西興部村", "帯広市", "音更町", "士幌町",
  "上士幌町", "鹿追町", "新得町", "清水町", "芽室町", "大樹町", "広尾町", "幕別町", "池田町", "豊頃町",
  "本別町", "足寄町", "陸別町", "浦幌町", "中札内村", "更別村", "釧路市", "釧路町", "厚岸町", "浜中町",
  "標茶町", "弟子屈町", "白糠町", "鶴居村", "根室市", "別海町", "中標津町", "標津町", "羅臼町"
];
