import React from "react";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "./ResponsiveAppBar";
import Container from "@mui/material/Container";

export default function HeaderContainerMixIN({ outlet }) {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ResponsiveAppBar />
        <div className="mt-16"></div>
        <Box sx={{ flexGrow: 1, display: "flex", m: 0, p: 0 }}>
          <Container maxWidth="lg" sx={{ p: 0 }}>
            <div className="bg-white mt-4">{outlet}</div>
          </Container>
        </Box>
      </Box>
    </>
  );
}
