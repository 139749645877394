import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";

export const ThankYouModal = ({ open, onClose, userId }) => {
  const handleClose = async () => {
    try {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        hasSeenThankYou: true,
      });
      onClose();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="thank-you-modal-title"
      aria-describedby="thank-you-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="thank-you-modal-title" variant="h6" component="h2">
          情報ありがとうございました。
        </Typography>
        <Typography id="thank-you-modal-description" sx={{ mt: 2 }}>
          それではMasterMindをお楽しみください。
        </Typography>
        <Button onClick={handleClose} sx={{ mt: 2 }}>
          閉じる
        </Button>
      </Box>
    </Modal>
  );
};
