import React, { useEffect, useState } from "react";
import { memo } from "react";
import MainContent from "./MainContent";
import WelcomeTour from "./WelcomeTour";
import { auth } from "../api/firebase"; // Firebase authのインポートが必要
import { WelcomeModal } from "./WelcomModal";

function Home() {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid); // ログインしているユーザーのUIDを設定
      } else {
        // ユーザーがログインしていない場合の処理
        setUserId(null);
      }
    });

    return () => unsubscribe(); // コンポーネントのアンマウント時にリスナーを解除
  }, []);

  return (
    <>
      <MainContent />
      <WelcomeModal userId={userId} />
    </>
  );
}

export default memo(Home);
