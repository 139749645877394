import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Menu,
  ListItemIcon,
  MenuList,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import ToDoList from "./ToDoList";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { db, auth } from "../api/firebase";
import { Link } from "react-router-dom"; // RouterLink としてインポート
import RightSections from "./RightSections";
import CloseIcon from "@mui/icons-material/Close";
import Vimeo from "@u-wave/react-vimeo";

function NavigationDrawer({ user }) {
  const [open, setOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("Logout successful");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error("Sign out error", error);
        setOpenSnackbar(true);
      });
  };

  const handleProfileClick = () => {
    handleCloseUserMenu(); // メニューを閉じる
    navigate("/profile"); // '/profile'にナビゲート
  };

  const handleHomeClick = () => {
    handleCloseUserMenu();
    navigate("/home");
  };

  const handleAdminSettingClick = () => {
    handleCloseUserMenu();
    navigate("/adminSetting");
  };

  const handleDDPClick = () => {
    handleCloseUserMenu();
    navigate("/ddp");
  };

  const handleVideoClick = () => {
    handleCloseUserMenu();
    navigate("/videos");
  };

  const handleToDoListClick = (event) => {
    event.stopPropagation(); // Stop the click event from propagating to the drawer toggle handler
  };

  // ナビゲーションメニューアイテムのデータを配列として定義
  const menuItems = [
    { text: "ホーム", icon: <HomeIcon />, onClick: handleHomeClick },
    {
      text: "DDP",
      icon: <FavoriteBorderIcon />,
      onClick: handleDDPClick,
      secondary: "DDPの設定を行います。",
    },
    user?.participatingGroup && {
      text: "グループ",
      icon: <Diversity1Icon />,
      onClick: () => {
        navigate(`/group/${user.participatingGroup}`);
      },
      secondary: "参加しているグループの情報を表示します。",
    },
    {
      text: "プロフィール設定",
      icon: <AccountCircleIcon />,
      onClick: handleProfileClick,
      secondary:
        "プロフィールの設定を行います。名前やアバター、居住県の変更はこちらで行います。",
    },
    { text: "ログアウト", icon: <LogoutIcon />, onClick: handleLogout },
  ].filter(Boolean); // `filter(Boolean)`で`false`の要素を除外

  const MenuItem = ({ icon, primary, secondary, onClick, hoverColor }) => (
    <ListItem
      onClick={onClick}
      sx={{
        "&:hover": {
          backgroundColor: hoverColor || "rgba(0, 0, 0, 0.04)",
          cursor: "pointer",
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography
            sx={{ fontWeight: "bold" }}
            component="div"
            variant="body1"
          >
            {primary}
          </Typography>
        }
        secondary={
          secondary && (
            <Typography
              sx={{ display: "inline", color: "text.secondary" }}
              component="div"
              variant="body2"
            >
              {secondary}
            </Typography>
          )
        }
      />
    </ListItem>
  );

  const drawerContent = (
    <Box onClick={handleToggleDrawer} sx={{ textAlign: "center", mx: 2 }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Menus
      </Typography>

      <IconButton
        onClick={handleToggleDrawer}
        sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
      >
        <CloseIcon />
      </IconButton>
      <Box onClick={handleToDoListClick} sx={{ textAlign: "left", mb: 2 }}>
        {isMobile && <RightSections />}
      </Box>

      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.text}>
            <MenuItem
              icon={item.icon}
              primary={item.text}
              secondary={item.secondary}
              onClick={() => {
                item.onClick();
                handleCloseUserMenu();
              }}
              hoverColor={item.hoverColor}
            />
            {index < menuItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleToggleDrawer}
        sx={{ mr: 2, display: "block" }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={open}
        onClose={handleToggleDrawer}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "100vw",
            maxWidth: 384,
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}

export default NavigationDrawer;
