import React, { useState } from 'react';
import { ImageList, ImageListItem } from '@mui/material';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const ImageRenderer = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  if (!images || images.length === 0) return null;

  const handleImageClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };


  return (
    <>
      <ImageList variant="quilted" cols={4} gap={8}>
        {images.map((image, index) => (
          <ImageListItem
            key={index}
            cols={index % 3 === 0 ? 2 : 1}
            rows={index % 3 === 0 ? 2 : 1}
            onClick={() => handleImageClick(index)}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={image.url}
              alt={`FAQ ${index + 1}`}
              loading="lazy"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={handleClose}
          slides={images.map(image => ({ src: image.url }))}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </>
  );
};

export default ImageRenderer;
